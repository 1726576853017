<template>
  <div class="content v-center">
    <AvatarBuilder @avatarChange="avatarChange" />
    <router-link :to="nextStep">
      <RoundedButton @click="storeAvatar">Spara och gå vidare</RoundedButton>
      <!-- <AboutSrhrButton /> -->
    </router-link>
  </div>
</template>

<script>
import RoundedButton from '@/components/RoundedButton';
/* import AboutSrhrButton from '@/components/AboutSrhrButton'; */
import AvatarBuilder from '@/components/AvatarBuilder';
import GET_SESSION from '@/graphql/GetSession.gql';

export default {
  apollo: {
    session: {
      query: GET_SESSION,
      update(data) {
        return data.session;
      },
    },
  },
  components: {
    RoundedButton,
    AvatarBuilder,
    /*  AboutSrhrButton, */
  },
  data() {
    return {
      avatarFilename: null,
    };
  },
  computed: {
    nextStep() {
      if (this.session.mode === 'SINGLE') {
        sessionStorage.setItem('healthcare', false);
        return { name: 'Quiz' };
      } else {
        return { name: 'PartyStart' };
      }
    },
  },
  methods: {
    avatarChange(avatarImageName) {
      this.avatarFilename = avatarImageName;
    },
    storeAvatar() {
      if (this.avatarFilename) {
        sessionStorage.setItem('avatar', this.avatarFilename);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  width: 100%;
}
</style>
