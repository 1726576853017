<template>
  <div class="content v-center">
    <Card>
      <Heading>Mata in er quizkod!</Heading>
      <p>Fyll i quizzets unika kod som er quizledare har på sin skärm.</p>
      <div>
        <input
          v-model="enteredPartyCode"
          type="tel"
          maxlength="6"
          placeholder="nnnnnn"
          @focus="partyMissing = false"
        />
        <template v-if="partyMissing">
          <div class="error">
            Kontrollera koden.
            <br />
            Det finns inget party med angiven kod
          </div>
        </template>
      </div>
    </Card>

    <RoundedButton @click="enterParty">Gå med</RoundedButton>
  </div>
</template>

<script>
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';
import PARTY_BY_CODE from '@/graphql/PartyByCode.gql';

export default {
  components: {
    Card,
    Heading,
    RoundedButton,
  },
  data() {
    return {
      enteredPartyCode: '',
      partyMissing: false,
    };
  },
  methods: {
    enterParty() {
      // Kolla om det verkligen finns ett party med den koden
      // Check if party exists
      this.$apollo
        .query({
          query: PARTY_BY_CODE,
          variables: {
            code: this.enteredPartyCode,
          },
        })
        .then(({ data: { partyByCode } }) => {
          if (!partyByCode) {
            this.partyMissing = true;
          } else {
            // Uppdatera participant till firestore
            sessionStorage.setItem(
              'party_participant_code',
              this.enteredPartyCode
            );

            this.$router.push({ name: 'PartyAvatar' });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: #ff1232;
  font-size: 14/14 * 1rem;
}

input {
  display: block;
  width: 155px;
  margin: auto;
  text-align: center;

  background: #fef7f1;
  border: 0;

  border-radius: 0;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  outline: 0;

  padding: 7px 7px 0;
  font-size: 40px;
  font-family: 'HelveticaNeue';
  font-weight: 700;

  &::placeholder {
    color: rgba(0, 0, 0, 0.1);
  }
}
</style>
