<template>
  <div class="content v-center">
    <Card>
      <Heading>Snart startar quizzet!</Heading>
      <h2>
        {{ $store.getters['getSetting']('quiz.participants_before_start') }}
      </h2>
    </Card>
  </div>
</template>

<script>
//import { partyDb, firebaseApp } from '@/firebase';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import PARTY_BY_CODE from '@/graphql/PartyByCode.gql';
import echoInstance from '../../../websocket';

export default {
  apollo: {
    quiz: {
      query: PARTY_BY_CODE,
      variables() {
        return { code: sessionStorage.getItem('party_participant_code') };
      },
      update: (data) => {
        return data.partyByCode.quiz;
      },
    },
  },
  components: {
    Card,
    Heading,
  },
  data() {
    return {
      echoInstance: null,
      party: false,
      partyCode: false,
      participantId: false,
    };
  },
  watch: {
    party(newValue) {
      if (newValue.status === 1) {
        this.$router.push({ name: 'PartyQuiz' });
      } else if (newValue.status === 2) {
        this.$router.push({ name: 'PartyEnd' });
      }
    },
  },

  created: function () {
    const sessionCode = sessionStorage.getItem('party_participant_code');
    const sessionId = sessionStorage.getItem('party_participant_id');
    this.partyCode = sessionCode;
    this.participantId = parseInt(sessionId, 10);

    this.echoInstance = echoInstance
      .join(`likaratt.${sessionCode}`)
      .here((users) => {
        // Alla users som är med i partyt har party som property
        // Sätt lokalt party till party för första användaren
        if (users.length > 0) {
          this.party = users[0].party;
        }
      })
      .listen('PartyStatusUpdated', (e) => {
        //console.log('PartyStatusUpdated', e);
        this.party = e.party;
      });
  },
  unmounted() {
    this.echoInstance = null;
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: #ff1232;
  font-size: 14/14 * 1rem;
}
</style>
