import { render, staticRenderFns } from "./AvatarBuilder.vue?vue&type=template&id=66d78cb4&scoped=true"
import script from "./AvatarBuilder.vue?vue&type=script&lang=js"
export * from "./AvatarBuilder.vue?vue&type=script&lang=js"
import style0 from "./AvatarBuilder.vue?vue&type=style&index=0&id=66d78cb4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d78cb4",
  null
  
)

export default component.exports