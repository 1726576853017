<template>
  <div class="content">
    <div class="desktop-view">
      <DesktopLogo class="desktop-logo" />
      <Card class="card" :minheight="160">
        <transition name="fade" mode="out-in">
          <div v-if="!analyzed && !error" key="analyze">
            <Heading desktop>Analyserar svaren...</Heading>
            <Spinner />
          </div>
          <div v-if="analyzed && !error" key="analyzed">
            <Heading desktop>Vilket quiz! Så hur gick det egentligen?</Heading>
            <p class="conclusion">
              Tillsammans lämnade ni {{ totalAnswers }} svar under det här
              quizzet och
              <span class="conclusion-highlight">
                {{ conclusionCorrectAnswers }}%
              </span>
              av dem var rätt!
              <br />
              <br />
              <span v-html="formattedConclusion" />
            </p>
          </div>
          <div v-if="error" key="error">
            <Heading desktop>Hoppla!</Heading>
            <p class="error">
              Nånting gick väldigt snett när datat skulle hämtas.
              <br />
              <br />
              Det verkar inte finnas något quize som är aktivt.
            </p>
          </div>
        </transition>
      </Card>
      <router-link v-if="analyzed && !error" :to="{ name: 'Start' }">
        <RoundedButton>Tillbaka till startsidan</RoundedButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import DesktopLogo from '@/components/DesktopLogo';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';
import Spinner from '@/components/Spinner';
import PARTY_BY_CODE from '@/graphql/PartyByCode.gql';

export default {
  apollo: {
    questions: {
      query: PARTY_BY_CODE,
      fetchPolicy: 'network-only',
      variables() {
        return {
          code: sessionStorage.getItem('party_admin_code'),
        };
      },
      update(data) {
        if (data.partyByCode) {
          return data.partyByCode.quiz.questions;
        } else {
          return {};
        }
      },
      result(result) {
        if (!result.error) {
          this.participants = result.data.partyByCode.participants;
          setTimeout(this.analyzeAnswers, 5000);
        }
      },
      error(error) {
        if (error.graphQLErrors || error.networkErrors) {
          this.error = true;
        }
      },
    },
  },
  components: {
    Card,
    DesktopLogo,
    Heading,
    RoundedButton,
    Spinner,
  },
  data() {
    return {
      error: false,
      partyCode: false,
      deleted: false,
      analyzed: false,
      correctQuestions: 0,
      allSameAnswers: 0,
      totalAnswers: 0,
      correctAnswers: 0,
      questions: false,
      results: [],
    };
  },
  computed: {
    conclusionCorrectAnswers() {
      return parseInt((this.correctAnswers / this.totalAnswers) * 100);
    },
    formattedConclusion() {
      let conclusion = this.$store.getters['getSetting'](
        'quiz.admin_conclusion'
      );
      return conclusion.replace(/\r\n/g, '<br>');
    },
  },
  created() {
    if (!this.partyCode) {
      this.partyCode = sessionStorage.getItem('party_admin_code');
      if (!this.partyCode) {
        this.error = true;
        return;
      }
    }
  },

  methods: {
    analyzeAnswers() {
      // Bygg en array med alla frågor
      this.prepareResultArray();
      this.buildResultArray();
      this.checkCorrectQuestions();
      this.setTotalAnswers();
      this.checkSameAnswerOnQuestion();

      this.analyzed = true;
      setTimeout(() => {
        sessionStorage.removeItem('party_admin_code');
        sessionStorage.removeItem('party_admin_cqi');
        sessionStorage.removeItem('party_db_id');
        this.deleted = true;
      }, 700);
    },
    buildResultArray() {
      //const participants = mockdata.participants;
      const participants = this.participants;
      participants.forEach((participant) => {
        participant.answers.forEach((answer) => {
          if (this.results[answer.question.id].type === 'multichoice') {
            this.results[answer.question.id].answers[answer.value - 1] += 1;
          } else if (this.results[answer.question.id].type === 'slider') {
            this.results[answer.question.id].answers[
              answer.value - this.results[answer.question.id].min
            ] += 1;
          } else if (this.results[answer.question.id].type === 'boolean') {
            this.results[answer.question.id].answers[answer.value] += 1;
          }
        });
      });
    },
    checkCorrectQuestions() {
      this.correctQuestions = 0;
      this.correctAnswers = 0;
      // console.log('participants', this.participants);
      // console.log('results', this.results);
      this.results.forEach((questionResult) => {
        let correct = questionResult.correct;

        questionResult.allCorrect = false;

        if (questionResult.type === 'multichoice') {
          correct = questionResult.correct - 1;
        } else if (questionResult.type === 'slider') {
          correct = questionResult.correct - questionResult.min;
        }
        //const participants = mockdata.participants.length;
        const participants = this.participants.length;

        if (questionResult.answers[correct] === participants) {
          questionResult.allCorrect = true;
          this.correctQuestions += 1;
        }
        this.correctAnswers += questionResult.answers[correct];
      });
    },
    checkSameAnswerOnQuestion() {
      this.allSameAnswers = 0;
      this.results.forEach((questionResult) => {
        let maxAnswers = Math.max(...questionResult.answers);
        let totalAnswers = questionResult.answers.reduce(function (acc, val) {
          return acc + val;
        }, 0);

        // console.log(
        //   'maxAnswers:' + maxAnswers + ' totalAnswers: ' + totalAnswers
        // );
        if (maxAnswers === totalAnswers) {
          this.allSameAnswers += 1;
        }
      });
    },
    setTotalAnswers() {
      let answers = 0;
      this.participants.forEach((participant) => {
        answers += participant.answers.length;
      });
      //console.log('totalAnswers', answers);
      this.totalAnswers = answers;
    },
    prepareResultArray() {
      this.results = [];
      this.questions.forEach((question) => {
        let questionObject = {};
        let questionData = [];
        if (question.multichoice) {
          questionObject.correct = question.multichoice.correct;
          questionObject.type = 'multichoice';
          for (let i = 1; i < 7; i += 1) {
            if (question.multichoice['alternative' + i]) {
              questionData[i - 1] = 0;
            }
          }
        } else if (question.slider) {
          questionObject.correct = question.slider.correct;
          questionObject.type = 'slider';
          questionObject.min = question.slider.min;
          const range = question.slider.max - question.slider.min;
          for (let i = 0; i <= range; i += 1) {
            questionData[i] = 0;
          }
        } else if (question.boolean) {
          questionObject.type = 'boolean';
          questionObject.correct = question.boolean.correct;
          questionData = [0, 0];
        }
        questionObject.answers = questionData;
        this.results[question.id] = questionObject;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
}
.error {
  color: #c40808;
}
.card {
  z-index: 1;
  margin-bottom: 15/14 * 1rem;
}
.desktop-logo {
  z-index: 2;
}

h1 {
  margin-top: 20/14 * 1rem;
  margin-bottom: 20/14 * 1rem;
}

.conclusion {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;

  font-size: 20/14 * 1rem;
  font-weight: 700;
  .conclusion-highlight {
    font-size: 24/14 * 1rem;
    font-weight: 700;
  }
}
</style>
