<template>
  <div class="content question-single">
    <transition name="fade">
      <div v-if="question">
        <DesktopLogo class="logo" />
        <QuestionSingleMode
          :question="question"
          :index="0"
          :total-questions="1"
          :validate="answered"
          :locked="answered"
          button-label="Tillbaka till startsidan"
          @change="onAnswerChange"
          @next="onNextClick"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import QuestionSingleMode from '@/components/QuestionSingleMode';
import DesktopLogo from '@/components/DesktopLogo';
import GET_QUESTION from '@/graphql/GetQuestion.gql';
export default {
  components: {
    DesktopLogo,
    QuestionSingleMode,
  },
  apollo: {
    question: {
      query: GET_QUESTION,
      variables() {
        return {
          id: this.routeParam,
        };
      },
      update: (data) => {
        return data.question;
      },
    },
  },
  data() {
    return {
      routeParam: this.$route.params.id,
      answered: false,
    };
  },
  /* mounted() {
    //console.log('params', this.$route.params);
  }, */
  methods: {
    onAnswerChange() {
      this.answered = true;
    },
    onNextClick() {
      this.$router.push({ name: 'Start' });
    },
  },
};
</script>

<style lang="scss" scoped>
.question-single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
}
.logo {
  position: relative;
  margin-top: -40px;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>
