<template>
  <div class="content v-center">
    <AvatarBuilder @avatarChange="avatarChange" />
    <RoundedButton @click="storeAvatar">Klar!</RoundedButton>
  </div>
</template>

<script>
import RoundedButton from '@/components/RoundedButton';
import AvatarBuilder from '@/components/AvatarBuilder';
import GET_SESSION from '@/graphql/GetSession.gql';
import CREATE_PARTICIPANT from '@/graphql/CreateParticipant.gql';
/* import { firebase } from '@firebase/app';
import { partyDb } from '@/firebase';
 */

export default {
  apollo: {
    session: {
      query: GET_SESSION,
      update(data) {
        return data.session;
      },
    },
  },
  components: {
    RoundedButton,
    AvatarBuilder,
  },
  data() {
    return {
      avatarFilename: null,
    };
  },
  computed: {
    nextStep() {
      if (this.session.mode === 'SINGLE') {
        return { name: 'Healthcare' };
      } else {
        return { name: 'PartyStart' };
      }
    },
  },
  methods: {
    avatarChange(avatarImageName) {
      this.avatarFilename = avatarImageName;
    },
    storeAvatar() {
      if (this.avatarFilename) {
        const partyCode = sessionStorage.getItem('party_participant_code');
        sessionStorage.setItem('avatar', this.avatarFilename);

        this.$apollo
          .mutate({
            mutation: CREATE_PARTICIPANT,
            variables: {
              code: partyCode,
              avatar: this.avatarFilename,
            },
          })
          .then(({ data }) => {
            sessionStorage.setItem(
              'party_participant_id',
              data.createParticipant.id
            );
            this.$router.push({ name: 'ParticipantWaitForAdmin' });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
