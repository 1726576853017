<template>
  <div class="content v-center">
    <Cloud odd single-char />
    <Card>
      <Avatar :avatar="sessionAvatar" class="avatar" />

      <div class="text-container">
        <p>
          Vilka patientmöten innehåller frågor kring SRHR? Inte alltid lätt att
          veta, och inte minst om våra egna känslor sätter käppar i hjulen.
        </p>

        <p>
          Här får du nu möta tre patienter som berättar om något som oroar dem.
          Men berättar de allt? Och håller du sinnet öppet? Lyssna och svara sen
          om du anser att det finns ett SRHR-perspektiv att ta hänsyn till.
        </p>
      </div>
    </Card>
    <router-link :to="{ name: 'Situations' }">
      <RoundedButton>Möt patienterna</RoundedButton>
    </router-link>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Cloud from '@/components/Cloud';
import RoundedButton from '@/components/RoundedButton';
export default {
  components: {
    Avatar,
    Card,
    Cloud,
    RoundedButton,
  },
  data() {
    return {
      sessionAvatar: false,
    };
  },
  created: function () {
    this.sessionAvatar = sessionStorage.getItem('avatar');
  },
};
</script>

<style lang="scss" scoped>
.cloud {
  position: relative;
  top: -20px;
  margin-bottom: 20px;

  margin-left: calc(50% + 20px) !important;
  margin-right: auto;
  order: 0 !important;
}
.avatar {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
}
.text-container {
  padding-top: 30px;
}
</style>
