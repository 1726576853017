<template>
  <div>
    <Card>
      <Heading mb30>{{ question.question }}</Heading>
      <img v-if="question.image" :src="question.image" class="question-image" />
      <MultiChoice
        v-if="question.multichoice"
        :has-facts="false"
        :locked="locked"
        :multichoice="question.multichoice"
        :validate="validate"
        @change="onAnswerChange"
      />
      <Slider
        v-if="question.slider"
        :has-facts="false"
        :locked="locked"
        :slider="question.slider"
        :validate="validate"
        @change="onAnswerChange"
      />
      <BooleanQT
        v-if="question.boolean"
        :boolean="question.boolean"
        :has-facts="false"
        :locked="locked"
        :validate="validate"
        @change="onAnswerChange"
      />
    </Card>
    <RoundedButton
      v-if="question.slider"
      :disabled="locked"
      @click="onLockAnswerClick"
    >
      <template v-if="!locked">
        {{ answerValue }} {{ question.slider.unit }}
        <ArrowRight class="arrow-right" />
      </template>
      <template v-else>Svaret skickat</template>
    </RoundedButton>
    <RoundedButton
      v-if="answerValue !== -1 && !question.slider"
      :disabled="locked"
      @click="onLockAnswerClick"
    >
      {{ lockLabel }}
    </RoundedButton>
  </div>
</template>

<script>
import ArrowRight from '@/assets/images/arrow-right-blue.svg';
import BooleanQT from '@/components/Boolean';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import MultiChoice from '@/components/MultiChoice';
import RoundedButton from '@/components/RoundedButton';
import Slider from '@/components/Slider';

export default {
  components: {
    ArrowRight,
    BooleanQT,
    Card,
    Heading,
    MultiChoice,
    RoundedButton,
    Slider,
  },
  props: {
    locked: {
      type: Boolean,
      default: () => false,
    },
    question: {
      type: Object,
      default: () => {},
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      answerValue: this.question.slider ? this.question.slider.min : -1,
      showInfoModal: false,
    };
  },
  computed: {
    nextLabel() {
      let label = 'Skicka svaret';
      if (this.question.slider) {
        label = this.answerValue + '>';
      }
      return label;
    },
    lockLabel() {
      return this.locked ? 'Svaret skickat' : 'Skicka ditt svar';
    },
  },

  methods: {
    onAnswerChange(val) {
      this.answerValue = val;
      this.$emit('change', val);
    },
    onLockAnswerClick() {
      this.$emit('lock-answer');
    },
  },
};
</script>

<style lang="scss" scoped>
.question-image {
  display: block;
  max-width: 50%;
  margin: 0.5em auto 0.5em auto;
}
.arrow-right {
  vertical-align: top;
  margin-top: -1px;
}
</style>
