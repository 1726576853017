import Vue from 'vue';
import Router from 'vue-router';
import Start from '@/views/Start';
import Avatar from '@/views/Avatar';
import Healthcare from '@/views/Healthcare';
import Quiz from '@/views/Quiz';
import QuizResult from '@/views/QuizResult';
import SrhrThoughts from '@/views/SrhrThoughts';
import MeetPatients from '@/views/MeetPatients';
import Situations from '@/views/Situations';
import Pledge from '@/views/Pledge';
import Pledges from '@/views/Pledges';
import PartyStart from '@/views/party/PartyStart';
import ChooseCategories from '@/views/party/admin/ChooseCategories';
import PartyWaitForParticipants from '@/views/party/admin/PartyWaitForParticipants';
import PartyAdminQuiz from '@/views/party/admin/Quiz';
import PartyAdminEnd from '@/views/party/admin/PartyAdminEnd';
import PartyQuiz from '@/views/party/participant/PartyQuiz';
import PartyEnd from '@/views/party/participant/PartyEnd';
import PartyAvatar from '@/views/party/participant/PartyAvatar';

import PartyWaitForAdmin from '@/views/party/participant/PartyWaitForAdmin';
import PartyJoin from '@/views/party/participant/PartyJoin';

import QuestionSingle from '@/views/QuestionSingle';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Start',
      component: Start,
    },
    {
      path: '/avatar',
      name: 'AvatarBuilder',
      component: Avatar,
    },
    {
      path: '/sjukvard',
      name: 'Healthcare',
      component: Healthcare,
    },
    {
      path: '/quiz',
      name: 'Quiz',
      component: Quiz,
    },
    {
      path: '/resultat',
      name: 'QuizResult',
      component: QuizResult,
    },
    {
      path: '/tankar',
      name: 'SrhrThoughts',
      component: SrhrThoughts,
    },
    {
      path: '/traffa-patienterna',
      name: 'MeetPatients',
      component: MeetPatients,
    },
    {
      path: '/situationer',
      name: 'Situations',
      component: Situations,
    },

    {
      path: '/löfte',
      name: 'Pledge',
      component: Pledge,
    },
    {
      path: '/löften',
      name: 'Pledges',
      component: Pledges,
    },
    {
      path: '/party/start',
      name: 'PartyStart',
      component: PartyStart,
    },
    {
      path: '/party/join',
      name: 'PartyJoin',
      component: PartyJoin,
    },
    {
      path: '/admin/kategorier',
      name: 'ChooseCategories',
      component: ChooseCategories,
      meta: {
        layout: 'desktop',
      },
    },
    {
      path: '/admin/wait',
      name: 'WaitParticipants',
      component: PartyWaitForParticipants,
      meta: {
        layout: 'desktop',
      },
    },
    {
      path: '/party/wait',
      name: 'ParticipantWaitForAdmin',
      component: PartyWaitForAdmin,
    },
    {
      path: '/admin/quiz',
      name: 'PartyAdminQuiz',
      component: PartyAdminQuiz,
      meta: {
        layout: 'desktop',
      },
    },
    {
      path: '/admin/end',
      name: 'PartyAdminEnd',
      component: PartyAdminEnd,
      meta: {
        layout: 'desktop',
      },
    },
    {
      path: '/party/quiz',
      name: 'PartyQuiz',
      component: PartyQuiz,
    },
    {
      path: '/party/end',
      name: 'PartyEnd',
      component: PartyEnd,
    },
    {
      path: '/party/avatar',
      name: 'PartyAvatar',
      component: PartyAvatar,
    },
    {
      path: '/fraga/:id',
      name: 'QuestionSingle',
      props: true,
      component: QuestionSingle,
    },
  ],
});
