<template>
  <div>
    <MultiChoiceButton
      v-for="choice in choices"
      :key="choice.index"
      :has-facts="hasFacts"
      :index="choice.index"
      :selected="selectedIndex === choice.index"
      :correct="validate && multichoice.correct === choice.index"
      :validate="validate"
      :wrong="
        validate &&
        selectedIndex === choice.index &&
        multichoice.correct != choice.index
      "
      :locked="locked"
      @click="onChoiceButtonClick"
      @info="onInfoClick"
    >
      {{ choice.label }}
    </MultiChoiceButton>
  </div>
</template>

<script>
import MultiChoiceButton from '@/components/MultiChoiceButton';

export default {
  components: {
    MultiChoiceButton,
  },
  props: {
    hasFacts: { type: Boolean, default: () => false },
    locked: { type: Boolean, default: () => false },
    multichoice: { type: Object, default: () => {} },
    validate: { type: Boolean, default: () => false },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    choices() {
      let c = [];
      this.multichoice.alternative1
        ? c.push({ label: this.multichoice.alternative1, index: 1 })
        : false;
      this.multichoice.alternative2
        ? c.push({ label: this.multichoice.alternative2, index: 2 })
        : false;
      this.multichoice.alternative3
        ? c.push({ label: this.multichoice.alternative3, index: 3 })
        : false;
      this.multichoice.alternative4
        ? c.push({ label: this.multichoice.alternative4, index: 4 })
        : false;
      this.multichoice.alternative5
        ? c.push({ label: this.multichoice.alternative5, index: 5 })
        : false;
      this.multichoice.alternative6
        ? c.push({ label: this.multichoice.alternative6, index: 6 })
        : false;

      return c;
    },
  },
  watch: {
    validate(newValidateValue) {
      if (newValidateValue === true) {
        const questionScore =
          this.selectedIndex === this.multichoice.correct ? 1 : 0;
        this.$emit('score-changed', questionScore);
      }
    },
  },
  methods: {
    onChoiceButtonClick(index) {
      this.selectedIndex = index;
      this.$emit('change', index);
    },
    onInfoClick() {
      this.$emit('info');
    },
  },
};
</script>

<style lang="scss" scoped></style>
