import GetSession from './GetSession.gql';

export const resolvers = {
  Mutation: {
    setMode: (_, { mode }, { cache }) => {
      const data = cache.readQuery({ query: GetSession });
      data.session.mode = mode;
    },
    setPartyCode: (_, { code }, { cache }) => {
      const data = cache.readQuery({ query: GetSession });
      data.session.party.code = code;
      cache.writeQuery({ query: GetSession, data });
    },
    setHealthcare: (_, { healthcare }, { cache }) => {
      const data = cache.readQuery({ query: GetSession });
      data.session.healthcare = healthcare;
    },
  },
};
