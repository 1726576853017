<template>
  <div class="">
    <Card>
      <Heading>Hur vill du se ut i quizzet?</Heading>

      <div class="avatar-parts">
        <div class="body">
          <img :src="avatarBody" />
        </div>
        <RoundedButton @click="randomizeAvatar">
          Slumpa fram en ny
        </RoundedButton>
      </div>
    </Card>
  </div>
</template>

<script>
import Heading from '@/components/Heading';
import Card from '@/components/Card';
import RoundedButton from '@/components/RoundedButton';

export default {
  components: {
    Card,
    Heading,
    RoundedButton,
  },
  data() {
    return {
      currentBody: 0,
      lastIndex: 0,
      skinToneDisabled: false,
      avatars: [
        'lion.png',
        'koala.png',
        'panda.png',
        'elephant.png',
        'bunny.png',
        'racoon.png',
        'reindeer.png',
        'frog.png',
        'giraffe.png',
        'pig.png',
        'rhino.png',
        'mouse.png',
        'bear.png',
        'monkey.png',
        'fox.png',
      ],
    };
  },
  computed: {
    avatarBody() {
      const filename = this.avatars[this.currentBody];
      return require(`../assets/images/avatars/${filename}`);
    },
  },

  created() {
    this.currentBody = Math.floor(Math.random() * this.avatars.length);
  },
  mounted() {
    this.$emit('avatarChange', this.getAvatarFilename());
  },
  methods: {
    randomizeAvatar() {
      // generate a random number between 5 and 15
      this.ramdomcount = 12; //Math.floor(Math.random() * 10) + 10;
      this.genreateRandomAvatar();
    },
    genreateRandomAvatar() {
      let randomIndex = 0;
      do {
        randomIndex = Math.floor(Math.random() * this.avatars.length);
      } while (randomIndex === this.lastIndex);
      this.currentBody = randomIndex;
      this.lastIndex = this.currentBody;
      this.$emit('avatarChange', this.getAvatarFilename());
      this.ramdomcount--;
      if (this.ramdomcount > 0) {
        setTimeout(() => {
          this.genreateRandomAvatar();
        }, 100);
      }
    },
    getAvatarFilename() {
      return this.avatars[this.currentBody];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-parts {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;

  .body {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    margin-bottom: 30px;

    img {
      width: 140px;
      height: auto;
    }
  }
}
</style>
