<template>
  <div class="content v-center view-pledges">
    <PledgeAvatar v-for="pledge in pledges" :key="pledge.id" :pledge="pledge" />
    <Card purple>
      <transition name="bounce">
        <Avatar v-if="animate" :avatar="sessionAvatar" class="avatar" />
      </transition>
      <div class="pledges-wrapper">
        <transition name="bounce" mode="out-in">
          <div v-if="!animate" key="old" class="counter" :style="counterStyle">
            {{ parseInt(sessionPledgeId - 1) }}
          </div>
          <div v-else key="new" class="counter" :style="counterStyle">
            {{ sessionPledgeId }}
          </div>
        </transition>
        <div class="label">löften</div>
      </div>
    </Card>

    <RoundedButton class="btn" @click="onShareClick">
      Dela Sexkampen
    </RoundedButton>
    <router-link :to="{ name: 'Start' }">
      <RoundedButton class="btn">Till huvudmenyn</RoundedButton>
    </router-link>
    <AboutSrhrButton class="btn" />
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import AboutSrhrButton from '@/components/AboutSrhrButton';
import Card from '@/components/Card';
import PledgeAvatar from '@/components/PledgeAvatar';
import RoundedButton from '@/components/RoundedButton';
import Utils from '@/utils/Utils';
import GET_PLEDGES from '@/graphql/GetPledges.gql';

export default {
  apollo: {
    pledges: {
      query: GET_PLEDGES,
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.pledges.data;
      },
    },
  },
  components: { AboutSrhrButton, Avatar, Card, PledgeAvatar, RoundedButton },
  data() {
    return {
      animate: false,
      sessionAvatar: false,
      sessionPledgeId: false,
    };
  },
  computed: {
    counterStyle() {
      let s = {};
      if (this.sessionPledgeId.length === 4) {
        s.fontSize = '132px';
      }
      if (this.sessionPledgeId.length > 4) {
        s.fontSize = '106px';
      }
      return s;
    },
  },
  created() {
    this.sessionAvatar = sessionStorage.getItem('avatar');
    this.sessionPledgeId = sessionStorage.getItem('pledgeId');
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 1000);
  },
  methods: {
    onShareClick() {
      if (navigator.share) {
        navigator.share({
          text: 'Sexkampen - Ett kunskapsspel om sex, reproduktion, rättigheter, dig själv, dina medmänniskor och andra livsviktiga grejer!',
          url: process.env.VUE_APP_SHARE_URL,
        });
      } else {
        Utils.copyToClipboard(process.env.VUE_APP_SHARE_URL);
        alert('Länken är kopierad till urklipp');
      }
    },
    avatarImage(imageName) {
      return require(`@/assets/images/avatars/${imageName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.pledges-wrapper {
  padding-top: 20/14 * 1rem;
  padding-bottom: 20/14 * 1rem;
}
.counter {
  font-family: 'HelveticaNeue', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 156/14 * 1rem;
  line-height: 1;
  text-align: center;
  color: #fff;
}
.label {
  font-family: 'HelveticaNeue', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36/14 * 1rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}
.btn {
  position: relative;
}

.avatar {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  transition-delay: 0.2s;
}
</style>
