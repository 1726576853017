<template>
  <div class="button-wrapper">
    <div
      class="rounded-button"
      :class="{ disabled: disabled }"
      @click="onClick"
    >
      <span><slot></slot></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {},
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  padding: 0;
  margin: 0 auto 8px auto;
  text-align: center;
  text-decoration: none;
  width: 100%;
  max-width: 320px;
  overflow: hidden;
  border: 4px solid rgba(254, 247, 241, 0.3);
  border-radius: 48px;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
}
.rounded-button {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 150px;
  width: 100%;

  margin: auto;
  padding: 15px 15px;

  background-color: #fef7f1;

  font-family: 'HelveticaNeue', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;

  letter-spacing: -0.02em;
  color: #303030;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.1s;

  cursor: pointer;

  span {
    position: relative;
    top: 2px;
  }

  &:active {
    background-color: $tap-highlight-color;
    transform: scale(1.05, 1.05);
  }

  &.disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3);
    background-color: #e7e7e7;
  }
}
</style>
