<template>
  <div class="spinner"><Spinner /></div>
</template>

<script>
import Spinner from '@/assets/images/spinner.svg';
export default {
  components: {
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}
</style>
