import { render, staticRenderFns } from "./Quiz.vue?vue&type=template&id=31c7a174&scoped=true"
import script from "./Quiz.vue?vue&type=script&lang=js"
export * from "./Quiz.vue?vue&type=script&lang=js"
import style0 from "./Quiz.vue?vue&type=style&index=0&id=31c7a174&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c7a174",
  null
  
)

export default component.exports