<template>
  <div class="outer-wrapper">
    <div id="app" ref="wrapper" class="wrapper-desktop">
      <transition name="layout-slide">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sexkampen',
  components: {},
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  methods: {
    handleResize() {
      this.$refs.wrapper.style.minHeight = window.innerHeight + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-desktop {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
}
</style>
