<template>
  <div class="content situations-container">
    <div class="status-bar">
      <RoundedLabel>
        patient {{ currentSituation + 1 }} / {{ situations.length }}
      </RoundedLabel>
    </div>
    <transition-group name="slide">
      <Situation
        v-for="(s, index) in situations"
        v-show="index === currentSituation"
        :key="s.id"
        :situation="s"
        @next="onNext"
      />
    </transition-group>
  </div>
</template>

<script>
import Situation from '@/components/Situation';
import RoundedLabel from '@/components/RoundedLabel';
export default {
  components: {
    RoundedLabel,
    Situation,
  },
  data() {
    return {
      situation: Object,
      currentSituation: 0,
    };
  },
  created() {
    this.situations = JSON.parse(sessionStorage.getItem('situations'));
  },
  methods: {
    onNext() {
      if (this.currentSituation >= this.situations.length - 1) {
        this.$router.push({ name: 'Pledge' });
      } else {
        this.currentSituation++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
}

.situations-container {
  > span {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    max-width: $view-max-width;
    /*  min-height: 100vh; */
    margin: 0 auto;

    > * {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}
.status-bar {
  margin-top: 10px;
}
</style>
