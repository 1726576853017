<template>
  <div class="multichoice-result">
    <apexchart
      width="50%"
      type="donut"
      :options="options"
      :series="series"
      class="chart"
    ></apexchart>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
export default {
  components: {
    apexchart,
  },
  props: {
    data: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
    correct: { type: [String, Number], default: () => '' },
  },
  data() {
    return {
      options: {
        labels: this.labels,
        colors: [
          '#F9B000',
          '#93328e',
          '#005eb8',
          '#e50076',
          '#003c68',
          '#008264',
        ],
        //colors: ['#FBB12E', '#92348C', '#0d60b5'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'bottom',
          fontFamily: 'Open sans',
          fontWeight: 600,
          horizontalAlign: 'left',
          fontSize: 20,
          itemMargin: {
            horizontal: 6,
            vertical: 6,
          },
          markers: {
            width: 26,
            height: 26,
            radius: 0,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      series: this.data,
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: left;
}
.multichoice-result {
  margin-bottom: 16px;
}
</style>
<style lang="scss">
.apexcharts-legend-marker {
  vertical-align: top;
}
</style>
