<template>
  <div class="content v-center">
    <Card class="pledge">
      <Heading>Du har ett livsviktigt uppdrag!</Heading>
      <p>
        Som yrkesverksamma får vi aldrig blunda för SRHR-området. Oavsett vad vi
        arbetar med så kommer vi på olika sätt gripa in i patientens liv och
        eftersom forskning tydligt visar att det finns samband mellan många
        sjukdomstillstånd och en försämrad sexuell och reproduktiv hälsa behöver
        vi ta ansvar för detta.
      </p>
      <p>Så vad kan du göra redan idag? Jo, du kan:</p>
      <ul>
        <li><strong>Hålla ett öppet sinne i alla möten</strong></li>
        <li>
          <strong>Ställa frågor som öppnar upp mötet</strong>
        </li>
        <li>
          <strong>
            Visa att det är OK att prata om SRHR genom visuella signaler såsom
            affischer och gratis kondomer/glidmedel.
          </strong>
        </li>
      </ul>

      <p>
        Allra först kan du lova dig själv att tänka på SRHR i varje möte, samtal
        och situation!
      </p>
    </Card>

    <transition name="fade" mode="out-in">
      <Spinner v-if="savingPledge" class="spinner" />
      <div v-else>
        <RoundedButton @click="savePledge">Jag lovar!</RoundedButton>
        <AboutSrhrButton />
      </div>
    </transition>
  </div>
</template>

<script>
import AboutSrhrButton from '@/components/AboutSrhrButton';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';
import Spinner from '@/components/Spinner';
import SAVE_PLEDGE from '@/graphql/SavePledge.gql';
export default {
  components: {
    AboutSrhrButton,
    Card,
    Heading,
    RoundedButton,
    Spinner,
  },
  data() {
    return {
      sessionAvatar: false,
      savingPledge: false,
    };
  },
  created() {
    this.sessionAvatar = sessionStorage.getItem('avatar');
  },
  methods: {
    savePledge() {
      if (!this.sessionAvatar) {
        //console.log('Error: no avatar!');
        return;
      }
      this.savingPledge = true;
      this.$apollo
        .mutate({
          mutation: SAVE_PLEDGE,
          variables: {
            avatar: this.sessionAvatar,
          },
        })
        .then((data) => {
          //console.log('pledge saved', data);
          sessionStorage.setItem('pledgeId', data.data.savePledge);
          this.$router.push({ name: 'Pledges' });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pledge {
  text-align: left;
}
.spinner {
  height: 60px;
  width: 60px;
}
</style>
