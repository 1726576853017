<template>
  <div class="content">
    <div class="quiz">
      <div class="status-bar">
        <RoundedLabel v-if="party.status" small>
          {{ currentQuestionLabel }}
        </RoundedLabel>
      </div>
      <div v-if="party.status" class="questions-container">
        <transition-group :name="transitionName">
          <!--  <Question key="eins" :question="quiz.questions[0]" /> -->
          <Question
            v-for="(question, index) in quiz.questions"
            v-show="index === party.current_question_index"
            :key="question.id"
            :ref="'q' + index"
            :question="question"
            :locked="locked"
            :validate="party.current_question_index === party.show_result"
            @change="onAnswerChange"
            @lock-answer="lockAnswer"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '@/components/Question';
import RoundedLabel from '@/components/RoundedLabel';

import PARTY_BY_CODE from '@/graphql/PartyByCode.gql';
import CREATE_ANSWER from '@/graphql/CreateAnswer.gql';

import echoInstance from '@/websocket';

export default {
  apollo: {
    quiz: {
      query: PARTY_BY_CODE,
      variables() {
        return {
          code: sessionStorage.getItem('party_participant_code'),
        };
      },
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.partyByCode.quiz;
      },
      result({ data }) {
        if (data.partyByCode) {
          this.party = data.partyByCode;
        }
      },
    },
  },
  components: {
    Question,
    RoundedLabel,
  },
  data() {
    return {
      echoInstance: null,
      locked: false,
      party: false,
      partyCode: false,
      participantId: false,
      quiz: false,
      currentQuestionAnswer: false,
    };
  },
  computed: {
    currentQuestionLabel() {
      let qLabel = '-/-';
      if (this.quiz.questions && this.party) {
        qLabel =
          parseInt(this.party.current_question_index + 1) +
          '/' +
          this.quiz.questions.length;
      }
      return qLabel;
    },
    transitionName() {
      return this.party.current_question_index ? 'slide' : 'fade';
    },
  },

  watch: {
    party(newP, oldP) {
      //console.log('PartyQuiz watch party', newP, oldP);
      if (newP.status === 0) {
        this.$router.push({ name: 'ParticipantJoin' });
      } else if (newP.status === 2) {
        sessionStorage.removeItem('party_participant_code');
        sessionStorage.removeItem('party_participant_id');
        this.$router.push({ name: 'PartyEnd' });
      }
      if (newP.current_question_index != oldP.current_question_index) {
        this.locked = false;
        this.currentQuestionAnswer = false;
      }
    },
  },
  created: function () {
    const sessionCode = sessionStorage.getItem('party_participant_code');
    const sessionId = sessionStorage.getItem('party_participant_id');
    this.partyCode = sessionCode;
    this.participantId = sessionId;

    this.echoInstance = echoInstance
      .join(`likaratt.${this.partyCode}`)
      .listen('PartyStatusUpdated', (e) => {
        //console.log('PartyQuiz PartyStatusUpdated', e);
        this.party = e.party;
      });
  },
  unmounted() {
    this.echoInstance = null;
  },
  methods: {
    lockAnswer() {
      this.locked = true;

      const val =
        this.$refs['q' + this.party.current_question_index][0].answerValue;
      //console.log('lockANswer', val);

      this.$apollo.mutate({
        mutation: CREATE_ANSWER,
        variables: {
          value: val.toString(),
          participantId: parseInt(this.participantId, 10),
          questionId: this.quiz.questions[this.party.current_question_index].id,
        },
      });
    },
    onAnswerChange(val) {
      this.currentQuestionAnswer = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: row nowrap;

  padding: 0;
}

.status-bar {
  margin-top: 15px;
}
.questions-container {
  > span {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    max-width: $view-max-width;
    margin: 0 auto;

    > * {
      flex: 0 0 100%;
      width: 100%;
      padding: 0 $padding-h;
      &:first-child {
        &.slide-enter,
        &.slide-enter-to {
          transform: none !important;
        }
      }
    }
  }
}
.quiz {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  > * {
    flex-shrink: 0;
  }

  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 30/14 * 1rem;
  }
}
.error {
  color: #ff1232;
  font-size: 14/14 * 1rem;
}

@media (min-height: 700px) {
  .status-bar {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
