<template>
  <div class="content v-center">
    <Card>
      <Heading>Quizza tillsammans!</Heading>
      <p>
        I partyläget kan ni quizza tillsammans och se hur lika...eller
        olika...ni svarar på livsviktiga frågor om sexuell och reproduktiv hälsa
        samt rättigheter!
      </p>
      <p>
        En av er, quizledaren, behöver skapa ett quiz och ge de andra en quizkod
        att ansluta till. Quizledaren kan även visa alla hur ni tillsammans
        svarat på frågorna på sin skärm.
      </p>
      <p>
        Som spelare väljer du bara att delta och matar sen in rätt quizkod för
        att hänga på!
      </p>
    </Card>
    <router-link :to="{ name: 'PartyJoin' }">
      <RoundedButton>Jag vill delta</RoundedButton>
    </router-link>
    <router-link :to="{ name: 'ChooseCategories' }">
      <RoundedButton>Jag vill skapa ett party</RoundedButton>
    </router-link>
  </div>
</template>

<script>
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';

export default {
  components: {
    Card,
    Heading,
    RoundedButton,
  },
};
</script>

<style lang="scss" scoped></style>
