<template>
  <div class="boolean">
    <BooleanChoice
      :correct-value="boolean.correct"
      :has-facts="hasFacts"
      :locked="locked"
      :selected-value="selectedValue"
      :validate="validate"
      :value="0"
      bg-color="#aa112c"
      @change="onChange"
      @info="onInfoClick"
    >
      Falskt
    </BooleanChoice>
    <!--    <div class="separator" /> -->
    <BooleanChoice
      :correct-value="boolean.correct"
      :has-facts="hasFacts"
      :locked="locked"
      :selected-value="selectedValue"
      :validate="validate"
      :value="1"
      bg-color="#2ca386"
      @change="onChange"
      @info="onInfoClick"
    >
      Sant
    </BooleanChoice>
  </div>
</template>

<script>
import BooleanChoice from '@/components/BooleanChoice';
export default {
  components: {
    BooleanChoice,
  },
  props: {
    boolean: { type: Object, default: () => {} },
    hasFacts: { type: Boolean, default: () => false },
    locked: { type: Boolean, default: () => false },
    validate: { type: Boolean, default: () => false },
  },
  data() {
    return {
      selectedValue: false,
    };
  },
  watch: {
    validate(newValidateValue) {
      if (newValidateValue === true) {
        const questionScore =
          this.selectedValue === this.boolean.correct ? 1 : 0;
        this.$emit('score-changed', questionScore);
      }
    },
  },
  methods: {
    onChange(val) {
      this.selectedValue = val;
      this.$emit('change', val);
    },
    onInfoClick() {
      this.$emit('info');
    },
  },
};
</script>

<style lang="scss" scoped>
.boolean {
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .separator {
    width: 1px;
    height: 128px;
    background: #c4c4c4;
  }
}
</style>
