<template>
  <div class="content">
    <div class="desktop-view">
      <DesktopLogo class="desktop-logo" />
      <Card>
        <div v-if="!partyMissing">
          <Heading desktop>Er quizkod är:</Heading>
          <h2>
            <span class="code">{{ partyCode }}</span>
          </h2>
          <h3>Anslutna spelare är:</h3>
          <div class="avatar-container">
            <template v-for="(participant, index) in participants">
              <div
                v-if="participant.state === 'online'"
                :key="index"
                class="avatar"
              >
                <div class="avatar-inner">
                  <img :src="avatarImage(participant.avatar)" />
                </div>
              </div>
            </template>
          </div>
          <h3>{{ participantCount }} st spelare</h3>
          <h3>Starta quizzet när alla spelare har dykt upp!</h3>
        </div>
        <div v-else>
          <h1>Quiz med kod {{ partyCode }} saknas.</h1>
        </div>
      </Card>
      <RoundedButton @click="startQuiz">Starta quiz</RoundedButton>
    </div>
  </div>
</template>

<script>
import DesktopLogo from '@/components/DesktopLogo';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';
import PARTY_BY_CODE from '@/graphql/PartyByCode.gql';
import UPDATE_PARTY from '@/graphql/UpdateParty.gql';
import UPDATE_PARTICIPANT_STATE from '@/graphql/UpdateParticipantState.gql';
import echoInstance from '@/websocket';

export default {
  components: {
    Card,
    Heading,
    RoundedButton,
    DesktopLogo,
  },
  data() {
    return {
      currentQuestion: -1,
      partyCode: 'n/a',
      participants: [],
      partyMissing: false,
      partiesRef: null,
      echoInstance: null,
    };
  },
  computed: {
    participantCount() {
      // iOs devices går offline direkt när enheten går i vila
      /*  const online = this.participants.filter(item => item.state === 'online');
      return online.length; */
      return this.participants.filter((item) => item.state === 'online').length;
    },
  },
  created() {
    const sessionCode = sessionStorage.getItem('party_admin_code');
    this.partyCode = sessionCode;

    if (this.partyCode) {
      // Check if party exists
      this.$apollo
        .query({
          query: PARTY_BY_CODE,
          variables: {
            code: this.partyCode,
          },
        })
        .then(({ data: { partyByCode } }) => {
          if (!partyByCode) {
            this.partyMissing = true;
          }
          this.participants = partyByCode.participants;
        });
    }

    this.echoInstance = echoInstance
      .join(`likaratt.${this.partyCode}`)
      .joining((user) => {
        //console.log('joining', user);
        this.setParticipantStatus(user.id, 'online');
      })
      .leaving((user) => {
        //console.log('leaving', user);
        this.setParticipantStatus(user.id, 'offline');
      })
      .listen('PartyStatusUpdated', (e) => {
        //console.log('PartyStatusUpdated', e);
        this.participants = e.party.participants;
      });
  },
  unmounted() {
    this.echoInstance = null;
  },

  methods: {
    startQuiz() {
      // Uppdatera status på partyt
      this.$apollo
        .mutate({
          mutation: UPDATE_PARTY,
          variables: {
            code: this.partyCode,
            status: 1,
            currentQuestion: 0,
          },
        })
        .then(() => {
          this.$router.push({ name: 'PartyAdminQuiz' });
        });
    },
    avatarImage(imageName) {
      //console.log(imageName);
      return require(`../../../assets/images/avatars/${imageName}`);
    },
    setParticipantStatus(participantId, status) {
      this.$apollo.mutate({
        mutation: UPDATE_PARTICIPANT_STATE,
        variables: {
          id: parseInt(participantId, 10),
          state: status,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  z-index: 1;

  margin-bottom: 15/14 * 1em;
}
.desktop-logo {
  z-index: 2;
}
h1 {
  margin-top: 20/14 * 1em;
  margin-bottom: 0/14 * 1em;
}
h2 {
  margin-top: 0/14 * 1em;
  font-size: 30/14 * 1em;
  margin-bottom: 10/14 * 1em;
}
h3 {
  font-size: 14/14 * 1em;
  margin-bottom: 20/14 * 1em;
}
.code {
  font-size: 1.5em;
  letter-spacing: 7px;
}
.avatar-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 120px;
}
.avatar {
  flex: 0 0 16.6667%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 30/14 * 1rem;

  .avatar-inner {
    border-radius: 100%;
    // border: 5px solid rgba(0, 0, 0, 0.2);
    width: 90px;
    height: 90px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      display: block;
      width: 65px;
      height: auto;
    }
  }
}
</style>
