<template>
  <div>
    <div class="toggle" @click="toggle">
      <slot name="header" @click="toggle"></slot>
    </div>
    <transition name="fade">
      <div v-if="expanded">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  > * {
    flex: 0 0 auto;
  }
}
</style>
