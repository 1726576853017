<template>
  <div>
    <Heading class="question" desktop mb30>{{ question.question }}</Heading>
    <img v-if="question.image" :src="question.image" class="question-image" />
    <MultiChoice
      v-if="question.multichoice"
      :has-facts="false"
      :locked="true"
      :multichoice="question.multichoice"
      :validate="false"
      class="multichoice"
    />
    <Slider
      v-if="question.slider"
      :has-facts="false"
      :locked="true"
      :slider="question.slider"
      :validate="false"
      :nodescription="true"
      :admin="true"
      class="slider"
    />
    <Boolean
      v-if="question.boolean"
      :boolean="question.boolean"
      :has-facts="false"
      :locked="true"
      :validate="false"
      class="boolean"
    />
  </div>
</template>

<script>
import Boolean from '@/components/Boolean';
import Heading from '@/components/Heading';
import MultiChoice from '@/components/MultiChoice';
import Slider from '@/components/Slider';

export default {
  components: {
    Boolean,
    Heading,
    MultiChoice,
    Slider,
  },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  max-width: 720px;
  margin: 0 auto 30px;
}
.question-image {
  display: block;
  max-width: 50%;
  margin: 0.5em auto 2.5em auto;
}
.arrow-right {
  vertical-align: middle;
}
.slider,
.boolean,
.multichoice {
  max-width: 460px;

  margin-left: auto;
  margin-right: auto;
}
</style>
