<template>
  <div class="info-button" @click="$emit('click')">
    <div class="info-button-inner">Läs mer</div>
  </div>
</template>

<script>
//import Info from '@/assets/images/info.svg';
export default {
  components: {
    //Info,
  },
};
</script>

<style lang="scss" scoped>
.info-button {
  cursor: pointer;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  animation: pulse 1.4s infinite ease-in-out;
  transition: transform 0.9s;
}
.info-button-inner {
  background-color: #005eb8;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  svg {
    display: block;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
}
</style>
