<template>
  <div class="label-wrapper" :class="{ noborder: noborder }">
    <div class="rounded-label" :class="{ disabled: disabled, small: small }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    small: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    noborder: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.label-wrapper {
  display: inline-block;
  padding: 0;
  margin: 0;
  text-align: center;
  text-decoration: none;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 25/14 * 1rem;
  &.noborder {
    border: 0;
  }
}
.rounded-label {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin: auto;
  padding: 8/14 * 1rem 20/14 * 1rem;

  border-radius: 20/14 * 1rem;
  background: #763a76;

  font-family: 'Open sans';
  font-weight: 700;
  font-size: 15/14 * 1rem;
  line-height: 19/14 * 1rem;

  color: #fff;
  text-transform: uppercase;

  &.small {
    padding: 2px 10px;
    min-height: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3em;
  }
}
</style>
