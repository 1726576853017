<template>
  <h1 :class="{ mb30, desktop }">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  props: {
    mb30: {
      type: Boolean,
      default: () => false,
    },
    desktop: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 700;
  font-size: 26/14 * 1rem;
  line-height: 110%;
  text-align: center;

  &.mb30 {
    margin-bottom: 30/14 * 1rem;
  }
  &.desktop {
    font-size: 32/14 * 1rem;
  }
}
</style>
