import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    aboutSrhrModalVisible: false,
    settings: [],
    settingsLoaded: false,
  },
  getters: {
    getSetting: (state) => (key) => {
      return state.settings.find((setting) => setting.key === key)?.value;
    },
  },
  mutations: {
    showAboutSrhrModal(state) {
      state.aboutSrhrModalVisible = true;
    },
    hideAboutSrhrModal(state) {
      state.aboutSrhrModalVisible = false;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    settingsLoaded(state, value) {
      state.settingsLoaded = value;
    },
  },
  actions: {
    showAboutSrhr(context) {
      context.commit('showAboutSrhrModal');
    },
    hideAboutSrhr(context) {
      context.commit('hideAboutSrhrModal');
    },
  },
});
