<template>
  <div class="content">
    <transition name="slide">
      <div v-if="!countdown" key="quiz" class="quiz">
        <div class="status-bar">
          <RoundedLabel small>{{ currentQuestionLabel }}</RoundedLabel>
          <QuestionCountdown
            :width="66"
            :height="66"
            :start-countdown="initQuestionCountdown"
            :reset-countdown="resetQuestionCountDown"
            :stop-countdown="stopQuestionCountDown"
            :countdown-duration="20"
            @countdown-end="onCountdownEnd"
          />
          <RoundedLabel small>
            <Star ref="star" />
            <span ref="score">&nbsp;{{ currentScore }}</span>
          </RoundedLabel>
        </div>
        <div class="questions-container">
          <transition-group name="slide">
            <QuestionSingleMode
              v-for="(question, index) in quiz.questions"
              v-show="index === currentQuestionIndex"
              :key="question.id"
              :ref="'q' + index"
              :index="index"
              :total-questions="quiz.questions.length"
              :question="question"
              :validate="index === showResult"
              :locked="index === showResult"
              @change="onAnswerChange"
              @next="onNextClick"
              @score-changed="onScoreChanged"
            />
          </transition-group>
        </div>
      </div>
      <div v-else class="countdown">
        <h1>
          Gör dig redo!
          <br />
          Quizzet startar om...
        </h1>
        <div class="seconds-left">{{ secondsLeft }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import QuestionSingleMode from '@/components/QuestionSingleMode';
import RoundedLabel from '@/components/RoundedLabel';
import SINGLE_MODE_QUIZ from '@/graphql/SingleModeQuiz.gql';
import QuestionCountdown from '@/components/QuestionCountdown';
import Star from '@/assets/images/star.svg';

export default {
  apollo: {
    quiz: {
      query: SINGLE_MODE_QUIZ,
      variables() {
        return {
          count: 10,
        };
      },
      fetchPolicy: 'network-only',
      update: (data) => {
        sessionStorage.setItem(
          'situations',
          JSON.stringify(data.singleModeQuiz.situations)
        );
        return data.singleModeQuiz;
      },
    },
  },
  components: {
    QuestionSingleMode,
    RoundedLabel,
    QuestionCountdown,
    Star,
  },
  data() {
    return {
      countdown: true,
      countdownStart: 5,
      currentQuestionIndex: 0,
      showResult: -1,
      elapsedTime: 0,
      startTime: 0,
      fill: { color: '#fff' },
      initQuestionCountdown: false,
      resetQuestionCountDown: false,
      stopQuestionCountDown: false,
      value: false,
      scores: [],
      currentScore: 0,
    };
  },
  computed: {
    secondsLeft() {
      return this.countdownStart - this.elapsedTime;
    },
    currentQuestionLabel() {
      let qLabel = '-/-';
      if (this.quiz.questions) {
        qLabel =
          this.currentQuestionIndex + 1 + '/' + this.quiz.questions.length;
      }
      return qLabel;
    },
  },
  watch: {
    currentScore() {
      this.$refs.score.classList.remove('animate-pulse');
      this.$refs.star.classList.remove('animate-tada');
      Vue.nextTick(() => {
        this.$refs.score.classList.add('animate-pulse');
        this.$refs.star.classList.add('animate-tada');
      });
    },
  },
  created() {
    sessionStorage.setItem('singleQuestionsCount', 10);
  },
  mounted() {
    setTimeout(() => {
      this.startTime = new Date().getTime();
      this.countdownTick();
    }, 900);
  },
  methods: {
    countdownTick() {
      const currentTime = new Date().getTime();
      this.elapsedTime = Math.round((currentTime - this.startTime) / 1000);

      if (this.countdownStart - this.elapsedTime >= 0) {
        setTimeout(this.countdownTick, 1000);
      } else {
        // Denna startar en slide
        this.countdown = false;
        this.startQuestionCountdown();
      }
    },
    onAnswerChange() {
      this.showResult = this.currentQuestionIndex;
      this.stopQuestionCountDown = true;
    },
    onNextClick() {
      this.resetQuestionCountDown = true;
      Vue.nextTick(() => {
        this.resetQuestionCountDown = false;
        this.advanceQuestions();
      });
    },
    startQuestionCountdown() {
      // Vänta på att slide är färdig innan vi triggar nedräknaren för frågan
      setTimeout(() => {
        this.initQuestionCountdown = true;
        Vue.nextTick(() => {
          this.initQuestionCountdown = false;
        });
      }, 900);
    },
    advanceQuestions() {
      if (this.currentQuestionIndex === this.quiz.total - 1) {
        this.$router.push({ name: 'QuizResult' });
      } else {
        this.stopQuestionCountDown = false;
        this.currentQuestionIndex++;
        this.startQuestionCountdown();
      }
    },
    onCountdownEnd() {
      this.resetQuestionCountDown = true;
      Vue.nextTick(() => {
        this.resetQuestionCountDown = false;
        this.advanceQuestions();
      });
    },
    onScoreChanged(score, index) {
      if (!this.scores.length) {
        this.scores = new Array(this.quiz.total);
      }
      this.scores[index] = score;
      let sco = 0;
      this.scores.map((value) => {
        sco = sco + value;
      });

      this.currentScore = sco;
      sessionStorage.setItem('score', sco);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: row nowrap;

  padding: 0;
}

.questions-container {
  > span {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    max-width: $view-max-width;
    /*  min-height: 100vh; */
    margin: 0 auto;

    > * {
      flex: 0 0 100%;
      width: 100%;
      padding: 0 $padding-h;
    }
  }
}
.status-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px $padding-h 0;
}
.countdown {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;

  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 30/14 * 1rem;
  }

  .seconds-left {
    font-family: 'HelveticaNeue';
    font-weight: 700;
    font-size: 358 / 14 * 1rem;
    line-height: 1;
    color: #fff;
  }
}
.quiz {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  > * {
    flex-shrink: 0;
  }

  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 30/14 * 1rem;
  }
}

.animate-tada {
  animation: tadaFrames linear 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}
.animate-pulse {
  animation: pulseFrames ease-in-out 0.5s;
  animation-delay: 0.5;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}

@keyframes tadaFrames {
  0% {
    transform: rotate(0deg) scaleX(1) scaleY(1);
  }
  10% {
    transform: rotate(-3deg) scaleX(0.8) scaleY(0.8);
  }
  20% {
    transform: rotate(-3deg) scaleX(0.8) scaleY(0.8);
  }
  30% {
    transform: rotate(3deg) scaleX(1.4) scaleY(1.4);
  }
  40% {
    transform: rotate(-3deg) scaleX(1.4) scaleY(1.4);
  }
  50% {
    transform: rotate(3deg) scaleX(1.4) scaleY(1.4);
  }
  60% {
    transform: rotate(-3deg) scaleX(1.4) scaleY(1.4);
  }
  70% {
    transform: rotate(3deg) scaleX(1.4) scaleY(1.4);
  }
  80% {
    transform: rotate(-3deg) scaleX(1.4) scaleY(1.4);
  }
  90% {
    transform: rotate(3deg) scaleX(1.4) scaleY(1.4);
  }
  100% {
    transform: rotate(0deg) scaleX(1) scaleY(1);
  }
}

@keyframes pulseFrames {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media (min-height: 700px) {
  .status-bar {
    margin-bottom: 10px;
  }
}
</style>
