<template>
  <div class="content v-center">
    <Card>
      <Heading mb30>
        Jobbar du
        <br />
        inom sjukvården?
      </Heading>
      <p>
        Sexkampen är ett kunskapsspel som vänder sig till ALLA människor
        eftersom frågorna den tar upp är så viktiga!
        <br />
        <br />
        Men jobbar du inom sjukvården så blir de ännu viktigare! Då ska du
        kryssa i gröna bocken så får du lite mer information om hur SRHR hänger
        ihop med ditt yrke.
      </p>
      <div class="answers">
        <router-link
          :to="{ name: 'Quiz' }"
          @click.native="setHealthcare('true')"
        >
          <Checkmark />
        </router-link>
        <div class="separator"></div>
        <router-link
          :to="{ name: 'Quiz' }"
          @click.native="setHealthcare('false')"
        >
          <Cross />
        </router-link>
      </div>
    </Card>
    <AboutSrhrButton />
  </div>
</template>

<script>
import AboutSrhrButton from '@/components/AboutSrhrButton';
import Card from '@/components/Card';
import Checkmark from '@/assets/images/checkmark.svg';
import Cross from '@/assets/images/x.svg';
import Heading from '@/components/Heading';

export default {
  components: { AboutSrhrButton, Card, Checkmark, Cross, Heading },
  methods: {
    setHealthcare(val) {
      sessionStorage.setItem('healthcare', val);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 35/14 * 1rem;
}

.answers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 300px;
  margin: auto;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid rgba(250, 162, 162, 0);
    padding: 10px;
    &:active,
    &:hover {
      border: 10px solid rgba(0, 0, 0, 0.1);
    }
  }
  .separator {
    height: 128px;
    width: 3px;
    border-radius: 4px;
    background: #ededed;
  }
}
</style>
