<template>
  <div class="card" :class="{ purple: purple }">
    <div class="card-inner" :style="getMinheight">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    minheight: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    getMinheight() {
      if (this.minheight !== 0) {
        return 'min-height: ' + this.minheight + 'px';
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  width: 100%;
  margin: 5px 0 16px 0;
  border-radius: 19px;
  border: 6px solid rgba(254, 247, 241, 0.3);
  &.purple {
    .card-inner {
      background-color: $purple;
    }
  }
}
.card-inner {
  position: relative;
  background-color: #fef7f1;
  border-radius: 13px;
  padding: 30/14 * 1rem 15/14 * 1rem;
}
</style>
