<template>
  <div class="cards situation-container">
    <transition name="slide">
      <div v-if="srhrRelated === -1" key="situation">
        <Card>
          <Heading mb30 v-html="situationTitle"></Heading>

          <MultiChoiceButton @click="srhrRelated = 0">
            Jag vet nog vad problemet är
          </MultiChoiceButton>
          <MultiChoiceButton @click="srhrRelated = 1">
            Jag behöver ställa fler öppna frågor
          </MultiChoiceButton>
        </Card>
      </div>
      <div v-else key="secret" class="secret">
        <Card>
          <div
            v-if="srhrRelated === 1"
            class="dyn-answer dyn-answer-srhr-related"
          >
            <span>
              Bra att du tänkte att det kunde finnas mer som patienten oroade
              sig för! Så vilka frågor skulle du kunna ha ställt för att skapa
              trygghet för patienten att öppna sig om det här?
            </span>
          </div>
          <div>Din patient har egentligen mer att säga dig:</div>
          <Heading>"{{ secret.title }}"</Heading>
          <div v-if="srhrRelated === 0" class="dyn-answer">
            <span>
              Hmmm...det här var svårt att förstå när ni började prata kring
              patientens oro. Eller hur? Så vilka frågor skulle du kunna ha
              ställt för att skapa trygghet för patienten att öppna sig om det
              här?
            </span>
          </div>
        </Card>
        <RoundedButton @click="onNextClick">Gå vidare</RoundedButton>
      </div>
    </transition>
  </div>
</template>

<script>
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import MultiChoiceButton from '@/components/MultiChoiceButton';
import RoundedButton from '@/components/RoundedButton';
export default {
  components: {
    Card,
    Heading,
    MultiChoiceButton,
    RoundedButton,
  },
  props: {
    situation: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      srhrRelated: -1,
    };
  },
  computed: {
    secret() {
      return this.situation.secrets[
        Math.floor(Math.random() * this.situation.secrets.length)
      ];
    },
    situationTitle() {
      return this.situation.text.replace(/<[^>]*>?/gm, '');
    },
  },
  methods: {
    onNextClick() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: $view-max-width;
  margin: 0 auto;

  > * {
    flex: 0 0 100%;
    width: 100%;
    padding: 0 10px;
  }
}

.secret {
  h1 {
    margin: 30px 0;
  }
}

.dyn-answer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  > * {
    flex: 1 1 auto;
  }
  img {
    flex: 0 0 70px;
    display: block;
    width: 70px;
    height: auto;
  }

  &.dyn-answer-srhr-related {
    margin-bottom: 30px;
  }
}
.answers {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 350px;
  margin: auto;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 10px solid rgba(250, 162, 162, 0);
    padding: 10px;
    &:active,
    &:hover {
      border: 10px solid rgba(0, 0, 0, 0.1);
    }
  }
  img {
    width: 75px;
    height: 75px;
  }
  .separator {
    height: 128px;
    width: 3px;
    border-radius: 4px;
    background: #ededed;
  }
}
</style>
