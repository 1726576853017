<template>
  <svg :width="width" :height="height" :viewbox="viewbox">
    <g>
      <circle
        id="border"
        :cx="cx"
        :cy="cy"
        :r="r"
        stroke="rgba(0, 0, 0, 0.1)"
        stroke-width="6"
        fill="transparent"
      />
      <circle
        id="path"
        :cx="cx"
        :cy="cy"
        :r="progressR"
        stroke="#92398C"
        stroke-width="1"
        fill="transparent"
      />
      <circle
        id="progress"
        :cx="cx"
        :cy="cy"
        :r="progressR"
        stroke="#fff"
        stroke-linecap="round"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumfence"
        :stroke-dashoffset="progress"
        :transform="progressTransform"
        fill="transparent"
      />
      <text
        :x="textCx"
        :y="textCy"
        style="
          font-family: 'HelveticaNeue';
          font-weight: 700;
          font-size: 22;
          fill: #fff;
        "
      >
        {{ progressNumber }}
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 34 },
    height: { type: Number, default: 34 },
    strokeWidth: { type: Number, default: 6 },
    countdownDuration: { type: Number, default: 10 },
    startCountdown: { type: Boolean, default: false },
    resetCountdown: { type: Boolean, default: false },
    stopCountdown: { type: Boolean, default: false },
  },
  data() {
    return {
      startTime: 0,
      elapsedTime: 0,
      internalStopCountdown: false,
    };
  },
  computed: {
    cx() {
      return this.width / 2 + this.strokeWidth / 2;
    },
    cy() {
      return this.height / 2 + this.strokeWidth / 2;
    },
    textCx() {
      if (this.progressNumber.length > 1) {
        return this.width / 2 - 10;
      } else {
        return this.width / 2 - 4.5;
      }
    },
    textCy() {
      return this.height / 2 + this.strokeWidth + 11 / 2;
    },
    r() {
      return this.width / 2 - this.strokeWidth;
    },
    progressR() {
      return this.width / 2 - 2 * this.strokeWidth;
    },
    circumfence() {
      return 2 * Math.PI * this.progressR;
    },
    progress() {
      return this.circumfence * (this.elapsedTime / this.countdownDuration);
    },
    progressNumber() {
      return this.secondsLeft.toString();
    },
    progressTransform() {
      return 'rotate(-90, ' + this.cx + ',' + this.cy + ')';
    },
    secondsLeft() {
      return Math.round(this.countdownDuration - this.elapsedTime);
    },
    viewbox() {
      return '0 0 ' + this.width + ' ' + this.height;
    },
  },
  watch: {
    resetCountdown(val) {
      if (val) {
        this.elapsedTime = 0;
        this.internalStopCountdown = false;
      }
    },
    startCountdown(val) {
      if (val) {
        this.startTime = new Date().getTime();
        this.countdownTick();
      }
    },
    stopCountdown(val) {
      this.internalStopCountdown = val;
    },
  },
  methods: {
    countdownTick() {
      const currentTime = new Date().getTime();
      this.elapsedTime = (currentTime - this.startTime) / 1000;

      if (
        this.countdownDuration - this.elapsedTime > 0 &&
        !this.internalStopCountdown
      ) {
        setTimeout(this.countdownTick, 10);
        this.$emit('countdown-progress', this.secondsLeft);
      } else if (
        this.countdownDuration - this.elapsedTime <= 0 &&
        !this.internalStopCountdown
      ) {
        this.$emit('countdown-end');
      } else if (this.internalStopCountdown) {
        this.$emit('countdown-stop');
      }
    },
  },
};
</script>

<style></style>
