<template>
  <div>
    <Card>
      <Heading mb30>{{ question.question }}</Heading>
      <img v-if="question.image" :src="question.image" class="question-image" />
      <MultiChoice
        v-if="question.multichoice"
        :multichoice="question.multichoice"
        :locked="locked"
        :validate="validate"
        :has-facts="question.facts ? true : false"
        @change="onAnswerChange"
        @info="toggleModal"
        @score-changed="onScoreChanged"
      />
      <Slider
        v-if="question.slider"
        :slider="question.slider"
        :locked="locked"
        :validate="validate"
        :has-facts="question.facts ? true : false"
        @change="onAnswerChange"
        @info="toggleModal"
        @score-changed="onScoreChanged"
      />
      <BooleanQT
        v-if="question.boolean"
        :boolean="question.boolean"
        :locked="locked"
        :validate="validate"
        :has-facts="question.facts ? true : false"
        @change="onAnswerChange"
        @info="toggleModal"
        @score-changed="onScoreChanged"
      />
    </Card>
    <div class="button-container">
      <RoundedButton
        v-if="question.slider && !sliderLocked"
        @click="setSliderAnswer"
      >
        Skicka mitt svar
        <!-- : {{ question.slider.unit }} {{ answerValue }} -->
        <ArrowRight class="arrow-right" />
      </RoundedButton>

      <RoundedButton
        v-if="
          (question.slider && sliderLocked) ||
          (!question.slider && answerValue != -1)
        "
        @click="onNextClick"
      >
        {{ nextLabel }}
      </RoundedButton>
      <Share v-if="answerValue != -1" @click="onShareClick" />
    </div>
    <transition name="fade">
      <Modal
        v-if="showInfoModal"
        heading="Fakta"
        :html-content="question.facts"
        @close="toggleModal"
      />
    </transition>
  </div>
</template>

<script>
import ArrowRight from '@/assets/images/arrow-right-blue.svg';
import BooleanQT from '@/components/Boolean';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import Modal from '@/components/Modal';
import MultiChoice from '@/components/MultiChoice';
import RoundedButton from '@/components/RoundedButton';
import Slider from '@/components/Slider';
import Share from '@/components/Share';
import Utils from '@/utils/Utils';

export default {
  components: {
    ArrowRight,
    BooleanQT,
    Card,
    Heading,
    Modal,
    MultiChoice,
    RoundedButton,
    Slider,
    Share,
  },

  props: {
    index: { type: Number, default: () => -1 },
    totalQuestions: { type: Number, default: () => -1 },
    locked: { type: Boolean, default: () => false },
    buttonLabel: { type: String, default: () => 'Gå vidare' },
    question: {
      type: Object,
      default: () => {},
    },
    validate: { type: Boolean, default: () => false },
  },
  data() {
    return {
      answerValue: this.question.slider ? this.question.slider.min : -1,
      showInfoModal: false,
      sliderLocked: false,
    };
  },
  computed: {
    nextLabel() {
      return this.index === this.totalQuestions - 1
        ? this.buttonLabel
        : 'Nästa fråga';
    },
  },

  methods: {
    onAnswerChange(val) {
      this.answerValue = val;
      if (!this.question.slider) {
        this.$emit('change', val);
      }
    },
    onNextClick() {
      this.$emit('next');
    },
    onScoreChanged(score) {
      this.$emit('score-changed', score, this.index);
    },
    setSliderAnswer() {
      this.sliderLocked = true;
      this.$emit('change', this.answerValue);
    },
    toggleModal() {
      //console.log('toggleModal');
      this.showInfoModal = !this.showInfoModal;
    },
    onShareClick() {
      const shareURL =
        process.env.VUE_APP_SHARE_URL + '/fraga/' + this.question.id;

      if (navigator.share) {
        navigator.share({
          /*  text:
            'Sexkampen - Ett kunskapsspel om sex, reproduktion, rättigheter, dig själv, dina medmänniskor och andra livsviktiga grejer!',
           */
          text: 'Likarättskampen - ' + this.question.question,
          url: shareURL,
        });
      } else {
        Utils.copyToClipboard(shareURL);
        alert('Länken är kopierad till urklipp');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-image {
  display: block;
  max-width: 50%;
  margin: 0.5em auto 0.5em auto;
}

.arrow-right {
  vertical-align: top;
  margin-top: -1px;
}
.button-container {
  min-height: 130px;
}
</style>
