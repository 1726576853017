
    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeDefinition","name":{"kind":"Name","value":"Session"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"id"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"mode"},"arguments":[],"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Mode"}}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"pledge"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"healthcare"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"party"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"LocalParty"}},"directives":[]}]},{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"Mode"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"SINGLE"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"PARTY"},"directives":[]}]},{"kind":"ObjectTypeDefinition","name":{"kind":"Name","value":"LocalParty"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"code"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"directives":[]}]}],"loc":{"start":0,"end":169}};
    doc.loc.source = {"body":"type Session {\n  id: Int\n  mode: [Mode!]\n  pledge: String!\n  healthcare: Boolean!\n  party: LocalParty\n}\n\nenum Mode {\n  SINGLE\n  PARTY\n}\n\ntype LocalParty {\n  code: Int\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
