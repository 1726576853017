<template>
  <div class="cloud" :class="{ odd, singleChar }">
    <div class="text-container">{{ text }}</div>
    <Cloud class="cloud-svg" />
  </div>
</template>

<script>
import Cloud from '@/assets/images/cloud.svg';
export default {
  components: {
    Cloud,
  },

  props: {
    text: {
      type: String,
      default: () => '?',
    },
    odd: {
      type: Boolean,
      default: () => false,
    },
    singleChar: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="scss" scoped>
.cloud {
  position: relative;
  width: 148px;
  &.singleChar {
    width: 74px;

    .text-container {
      padding: 5px 5px 25px 5px;
      font-size: 30px;
    }
  }

  &.odd {
    margin-right: 0;
    margin-left: auto;
    order: 2;
    .cloud-svg {
      transform: scaleX(-1);
    }
  }
}
.text-container {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  padding: 20px 15px 45px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-weight: 700;
  font-size: 13px;
  line-height: 110%;
  /* or 13px */
  text-align: center;
  text-transform: uppercase;

  color: #000000;
}
</style>
