<template>
  <div class="content">
    <div class="desktop-view">
      <DesktopLogo class="desktop-logo" />
      <Card class="card">
        <Heading desktop>Välj kategorier till quizzet</Heading>
        <transition name="fade" mode="out-in">
          <Spinner v-if="!categories.length" />
          <div v-else>
            <h2>
              Vilka frågekategorier ska quizzet innehålla? Vilka SHRH-frågor har
              dina spelare bra och dålig koll på?
              <br />
              Här kan du vara snäll eller verkligen utmana dem!
            </h2>
            <div class="categories">
              <div
                v-for="(category, index) in categories"
                :key="index"
                class="category"
              >
                <input
                  :id="category.name"
                  :key="`inp_${category.id}`"
                  v-model="selectedCategories"
                  type="checkbox"
                  :name="category.name"
                  :value="category.id"
                />
                <label :key="`lbl_${category.id}`" :for="category.name">
                  {{ category.name }}
                </label>
              </div>
              <div class="category filler" />
              <div class="category filler" />
            </div>
          </div>
        </transition>
      </Card>

      <RoundedButton @click="createParty">Gå vidare</RoundedButton>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import DesktopLogo from '@/components/DesktopLogo';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';
import Spinner from '@/components/Spinner';
import CREATE_PARTY from '@/graphql/CreateParty.gql';
import GET_CATEGORIES from '@/graphql/GetCategories.gql';

export default {
  apollo: {
    categories: {
      query: GET_CATEGORIES,
    },
  },
  components: {
    Card,
    DesktopLogo,
    Heading,
    RoundedButton,
    Spinner,
  },
  data: () => ({
    categories: [],
    selectedCategories: [],
  }),

  methods: {
    createParty: function () {
      let code = Math.round(Math.random() * 999999).toString();
      code = code.padStart(6, '0');

      this.$apollo
        .mutate({
          mutation: CREATE_PARTY,
          variables: {
            categories: this.selectedCategories,
            code: code,
            count: 10,
          },
        })
        .then(({ data }) => {
          sessionStorage.setItem('party_admin_code', code);
          sessionStorage.setItem('party_db_id', data.createParty.id);
          sessionStorage.setItem('party_admin_cqi', 0);

          this.$router.push({ name: 'WaitParticipants' });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 20/14 * 1rem;
  margin-bottom: 20/14 * 1rem;
}
h2 {
  font-size: 14/14 * 1rem;
  margin-bottom: 20/14 * 1rem;
}
.content {
  padding: 0;
}
.card {
  z-index: 1;
  margin-bottom: 15/14 * 1rem;
}
.desktop-logo {
  z-index: 2;
}
.text-container {
  margin-bottom: 40px;
}
.text-container-inner {
  position: relative;
  margin-top: 30px;

  font-size: 15/375 * 100 + vw;

  border-radius: 30px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
}

.text-box {
  border-radius: 30px;
  background-color: #fff;
  padding: 45/14 * 1em 10/14 * 1em 30/14 * 1em;
  font-weight: 700;
  font-style: italic;

  line-height: 150%;
  /* or 22px */

  text-align: center;
}

.categories {
  width: 90%;
  margin: auto;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.category {
  flex: 1 1 33%;
  text-align: left;
  padding: 7px 0;
  &.filler {
    height: 0;
  }

  label {
    color: #303030;
    margin-left: 7px;
  }
}

@media (min-width: 750px) {
  .thoughts-container {
    font-size: 7.5px;
  }
  .text-container-inner {
    font-size: 30px;
  }
}
</style>
