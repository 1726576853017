import Echo from 'laravel-echo';

// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';
// window.Pusher = Pusher;

const echoInstance = new Echo({
  broadcaster: 'pusher',
  key: '1234asdf',
  cluster: 'mt-1',
  wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
  wsPort: 6001,
  wssPort: 443,
  forceTLS: false,
  disableStats: false,
  encrypted: false,
  enabledTransports: ['ws', 'wss'],
  //client: client
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        const data = new URLSearchParams();
        data.append('socket_id', socketId);
        data.append('channel_name', channel.name);

        if (sessionStorage.getItem('party_participant_id')) {
          data.append(
            'participant_id',
            sessionStorage.getItem('party_participant_id')
          );
        }
        fetch(process.env.VUE_APP_BACKEND_HOST + '/broadcast/auth', {
          method: 'POST',
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            callback(null, data);
          })
          .catch((error) => {
            callback(error);
          });
      },
    };
  },
  //authEndpoint: 'http://localhost/broadcasting/auth',
});

export default echoInstance;
