<template>
  <div class="content v-center">
    <Card>
      <Avatar :avatar="sessionAvatar" class="avatar" />
      <Heading class="heading">Ditt resultat:</Heading>
      <h2>{{ sessionScore }} av {{ questionsCount }}</h2>

      <div>
        <p>{{ summaryCopy }}</p>
      </div>
    </Card>

    <div v-if="sessionHealthcare">
      <router-link :to="{ name: 'SrhrThoughts' }">
        <RoundedButton>Jag vill lära mig mer</RoundedButton>
      </router-link>
      <AboutSrhrButton />
    </div>
    <div v-else>
      <RoundedButton @click="onShareClick">Dela Likarättskampen</RoundedButton>
      <router-link :to="{ name: 'Quiz' }">
        <RoundedButton>Kör igen</RoundedButton>
      </router-link>
      <router-link :to="{ name: 'Start' }">
        <RoundedButton>Till huvudmenyn</RoundedButton>
      </router-link>
      <AboutSrhrButton />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import AboutSrhrButton from '@/components/AboutSrhrButton';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import RoundedButton from '@/components/RoundedButton';
import Utils from '@/utils/Utils';

export default {
  components: {
    Avatar,
    AboutSrhrButton,
    Card,
    Heading,
    RoundedButton,
  },
  data() {
    return {
      sessionAvatar: false,
      sessionHealthcare: Boolean,
      sessionScore: String,
      questionsCount: Number,
    };
  },
  computed: {
    summaryCopy() {
      let s;
      let correctPercentage = (this.sessionScore / this.questionsCount) * 100;

      if (correctPercentage <= 50) {
        s = 'Det får du göra om.';
      } else if (correctPercentage > 50) {
        s =
          'Vad bra att ni tog er igenom den här quizen, för det här är inte några lätta frågor!';
      }

      /* switch (this.sessionScore) {
        case '0':
        case '1':
        case '2':
        case '3':
          break;
        case '4':
        case '5':
        case '6':
        default:
          s =
            'Vad bra att ni tog er igenom den här quizen, för det här är inte några lätta frågor!';
          break;
      } */
      return s;
    },
  },
  created: function () {
    this.sessionHealthcare = false;
    this.sessionScore = sessionStorage.getItem('score');
    this.questionsCount = sessionStorage.getItem('singleQuestionsCount');
    this.sessionAvatar = sessionStorage.getItem('avatar');
  },
  methods: {
    onShareClick() {
      if (navigator.share) {
        navigator.share({
          text: 'Sexkampen - Ett kunskapsspel om sex, reproduktion, rättigheter, dig själv, dina medmänniskor och andra livsviktiga grejer!',
          url: process.env.VUE_APP_SHARE_URL,
        });
      } else {
        Utils.copyToClipboard(process.env.VUE_APP_SHARE_URL);
        alert('Länken är kopierad till urklipp');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  padding-top: 20px;
  margin: 10px 0;
}
h2 {
  margin: 0;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 110%;
}
.avatar {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
}
</style>
