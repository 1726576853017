<template>
  <div class="slider-result">
    <div class="answers-container">
      <div
        v-for="(answer, index) in data"
        :key="index"
        class="answer"
        :style="answerStyles"
      >
        <Avatar
          v-for="(avatar, k) in answer"
          :key="k"
          class="avatar"
          :avatar="avatar"
          tiny
        />
        <!-- <Avatar
          v-for="(avatar, k) in new Array(Math.floor(Math.random() * 20))"
          :key="k"
          class="avatar"
          avatar="mb0t0.png"
          tiny
        /> -->
      </div>
    </div>
    <div class="labels-container" :style="labelsContainerStyles">
      <div class="label">
        <span>{{ labels[0] }}</span>
      </div>
      <div class="label">
        <span>{{ labels[Math.floor(labels.length / 2)] }}</span>
      </div>
      <div class="label">
        <span>{{ labels[labels.length - 1] }}</span>
      </div>
    </div>
    <div class="correct-container">
      <div class="correct-answer" :style="correctStyles">
        <div class="dotted-line" />
        <span :style="correctLabelStyles">{{ correct }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
export default {
  components: { Avatar },
  props: {
    data: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
    correct: { type: Number, default: () => 0 },
  },
  data() {
    return {};
  },
  computed: {
    answerStyles() {
      let s = {};
      s.flexBasis = 100 / this.data.length + '%';
      s.width = 100 / this.data.length + '%';
      return s;
    },
    correctStyles() {
      let s = {};

      s.flexBasis = 100 / this.data.length + '%';
      s.width = 100 / this.data.length + '%';
      s.left = (100 / this.data.length) * (this.correct - this.labels[0]) + '%';
      return s;
    },
    correctLabelStyles() {
      let s = {};

      if (String(this.correct).length > 2) {
        s.fontSize = '13px';
      }

      return s;
    },
    labelsContainerStyles() {
      let s = {};

      s.width = 100 - 100 / this.data.length + '%';
      return s;
    },
  },
  created() {
    /* console.log('data', this.data);
    console.log('labels', this.labels);
    console.log('answerStyles', this.answerStyles); */
  },
};
</script>

<style scoped lang="scss">
.slider-result {
  position: relative;
  width: calc(100% - 40px);

  margin: 0px auto 100px auto;
}

.answers-container {
  width: 100%;
  min-height: 200px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 20px;

  .answer {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .avatar {
      margin-bottom: -16px;
    }
  }
}

.correct-container {
  position: absolute;
  bottom: -64px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .correct-answer {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .dotted-line {
      position: absolute;
      bottom: 0;
      height: 200px;
      border-left: 2px dashed $green;
    }
    span {
      position: absolute;
      bottom: 0;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      background: #2ca386;
      border-radius: 25px;
    }
  }
}
.labels-container {
  position: absolute;
  bottom: -35px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top: 4px solid #000;
  .label {
    position: relative;
    width: 2px;
    height: 25px;
    background-color: #000;
    span {
      position: absolute;
      bottom: -20px;

      font-weight: bold;
      font-size: 16px;
      line-height: 110%;
      color: #303030;
      transform: translateX(-50%);
    }
  }
}
</style>
