<template>
  <div class="pledge-avatar-wrapper" :style="randPosition()">
    <div
      class="pledge-avatar"
      :style="randTransition()"
      :class="{ mounted: mounted }"
    >
      <div class="avatar-inner">
        <img :src="avatarImage(pledge.avatar)" />
      </div>
      <div class="badge">
        {{ pledge.id }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pledge: { type: Object, default: () => {} },
  },
  data() {
    return {
      mounted: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 100);
  },

  methods: {
    avatarImage(imageName) {
      return require(`@/assets/images/avatars/${imageName}`);
    },
    randPosition() {
      let s = {};
      s.transform =
        ' translate(' +
        parseInt(Math.random() * 100 - 50) +
        'vw, ' +
        parseInt(Math.random() * 100 - 50) +
        'vh)';
      return s;
    },
    randTransition() {
      let s = {};
      s.transitionDelay = Math.random() * 4 + 's ';
      return s;
    },
  },
};
</script>

<style lang="scss" scoped>
.pledge-avatar-wrapper {
  position: absolute;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  width: 70px;
  height: 70px;
}
.pledge-avatar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  transform: scale(0, 0);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &.mounted {
    transform: scale(1, 1);
  }

  .avatar-inner {
    border-radius: 100%;
    border: 5px solid rgba(0, 0, 0, 0.2);
    width: 70px;
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;

    background-color: #fff;
    img {
      display: block;
      width: 55px;
      height: auto;
    }
  }

  .badge {
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    border-radius: 50%;
    background-color: $purple;
  }
}
</style>
