<template>
  <div v-if="avatar" class="avatar" :class="{ tiny }">
    <div class="avatar-inner">
      <img :src="avatarImage(avatar)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: () => '',
    },
    tiny: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    avatarImage(imageName) {
      return require(`@/assets/images/avatars/${imageName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  &.tiny {
    width: 34px;
    height: 34px;
    flex: 0 0 34px;
    .avatar-inner {
      width: 34px;
      height: 34px;
      /*  border: 2px solid rgba(0, 0, 0, 0.2); */

      img {
        max-width: 80%;
      }
    }
  }

  .avatar-inner {
    border-radius: 100%;
    /*  border: 5px solid rgba(0, 0, 0, 0.2); */
    width: 116px;
    height: 116px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    background-color: #fef7f1;
    img {
      display: block;
      width: 75px;
      height: auto;
    }
  }
}
</style>
