<template>
  <transition name="fade">
    <div v-show="$store.state.settingsLoaded" class="applicatiom-wrapper">
      <transition name="layout-slide">
        <component :is="layout">
          <router-view />
        </component>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Asa',

  head: {
    title: 'Sexkaasaampen',
  },
  computed: {
    layout() {
      const setLayout = !this.$route.meta.layout
        ? 'default'
        : this.$route.meta.layout;

      return setLayout + '-layout';
    },
  },
};
</script>

<style lang="scss">
.applicatiom-wrapper {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
</style>
