<template>
  <div class="about-SRHR-button">
    <SmallButton @click="showAboutSRHR">Läs mer om lika rätt</SmallButton>
  </div>
</template>

<script>
import SmallButton from '@/components/SmallButton';
export default {
  components: {
    SmallButton,
  },
  methods: {
    showAboutSRHR() {
      this.$store.dispatch('showAboutSrhr');
    },
  },
};
</script>

<style lang="scss" scoped>
.about-SRHR-button {
  margin: 6px 0 32px 0;
}
</style>
