<template>
  <div class="content">
    <Card>
      <Heading>
        Forskning visar att hälso- och sjukvårdspersonal tycker att det är svårt
        att prata om sexuell hälsa av flera anledningar. Känner du igen dig i de
        här tankarna?
      </Heading>
    </Card>
    <div class="cloud-container">
      <transition-group name="fade">
        <Cloud
          v-for="(thought, index) in thoughts"
          v-show="currThought == index"
          :key="thought"
          :odd="index % 2 ? true : false"
          :text="thought"
          class="cloud"
        />
      </transition-group>
    </div>
    <Avatar :avatar="sessionAvatar" />
    <router-link class="btn" :to="{ name: 'MeetPatients' }">
      <RoundedButton>Gå vidare</RoundedButton>
    </router-link>
  </div>
</template>

<script>
import Cloud from '@/components/Cloud';
import Heading from '@/components/Heading';
import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import RoundedButton from '@/components/RoundedButton';
export default {
  components: {
    Avatar,
    Card,
    Cloud,
    Heading,
    RoundedButton,
  },
  data() {
    return {
      currThought: 0,
      thoughts: [
        'Jag har ingen utbildning',
        'Det känns obehagligt',
        'Jag kan inte tillräckligt',
        'Jag vet inte hur',
        'Det är inte mitt jobb',
        'Tänk om patienten tycker att jag är pervers',
        'Uh, pinsamt!',
        'Jag har aldrig gjort det',
      ],
    };
  },

  created() {
    this.sessionAvatar = sessionStorage.getItem('avatar');
  },
  mounted() {
    this.nextThought();
  },
  methods: {
    nextThought() {
      this.currThought =
        this.currThought + 1 < this.thoughts.length ? this.currThought + 1 : 0;
      setTimeout(this.nextThought, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.cloud-container {
  margin: 10px auto 0 auto;
  height: 141px;

  span {
    display: flex;
    flex-wrap: nowrap;
    width: 340px;
    max-width: calc(100vw - 20px);
    margin: auto;
    > * {
      flex: 0 1 auto;
      max-width: 50%;
    }

    .cloud {
      &.fade-leave-active {
        transition-delay: 1.4s;
      }
    }
  }
}

.btn {
  margin-top: 15px;
}
</style>
