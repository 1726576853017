<template>
  <div class="choice" :class="classes" @click="onClick">
    <slot></slot>
    <InfoButton
      v-if="correct && hasFacts"
      class="info-button"
      @click="onInfoClick"
    />
  </div>
</template>

<script>
import InfoButton from '@/components/InfoButton';

export default {
  components: {
    InfoButton,
  },
  props: {
    correctValue: { type: Number, default: () => -1 },
    hasFacts: { type: Boolean, default: () => false },
    locked: { type: Boolean, default: () => false },
    selectedValue: { type: [Boolean, Number], default: () => -1 },
    validate: { type: Boolean, default: () => false },
    value: { type: Number, default: () => -1 },
  },
  computed: {
    classes() {
      let c = [];
      if (this.selectedValue === this.value) {
        c.push('selected');
      }
      if (this.correct) {
        c.push('correct');
      }
      if (this.wrong) {
        c.push('wrong');
      }
      if (this.validate) {
        c.push('validate');
      }

      return c;
    },

    wrong() {
      return (
        this.validate &&
        this.selectedValue === this.value &&
        this.value !== this.correctValue
      );
    },
    correct() {
      return this.validate && this.value === this.correctValue;
    },
    selected() {
      return this.selectedValue === this.value;
    },
  },
  methods: {
    onClick() {
      if (!this.locked) {
        this.$emit('change', this.value);
      }
    },
    onInfoClick() {
      this.$emit('info');
    },
  },
};
</script>

<style lang="scss" scoped>
.choice {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;

  font-family: 'HelveticaNeue', sans-serif;
  font-size: 20px;
  color: #303030;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  text-align: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  border-radius: 50%;
  border: 3px solid rgba(0, 94, 184, 0.2);
  transition: color 0.2s, background 0.2s, opacity 0.2s linear 0.5s,
    border-color 0.2s;
  &:active {
    background-color: $tap-highlight-color;
  }
  &.wrong,
  &.correct,
  &.selected {
    color: #ffffff;
  }
  &.selected {
    background: #303030;
  }

  &.validate {
    &:not(.correct) {
      opacity: 0.2;
    }
  }
  &.wrong {
    background: $red;
    border-color: $red;
  }

  &.correct {
    background: $green;
    border-color: $green;
    &:not(.selected) {
      transition-delay: 0.5s;
    }
  }
}
.info-button {
  position: absolute;
  top: -22px;
  right: -15px;
}
</style>
