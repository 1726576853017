<template>
  <div class="share-button" @click="$emit('click')">
    <div class="share-button-inner"><Share class="icon" /></div>
  </div>
</template>

<script>
import Share from '@/assets/images/share-solid.svg';
export default {
  components: {
    Share,
  },
};
</script>

<style lang="scss" scoped>
.share-button {
  margin-top: 6px;
  display: inline-block;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.share-button-inner {
  background-color: #fef7f1;
  padding: 10px;
  border-radius: 50%;
  .icon {
    display: block;
    width: 32px;
    height: auto;
  }
}
</style>
