<template>
  <div class="content main">
    <div class="top">
      <div class="top-wrapper">
        <div class="info">
          <div class="thoughts-container">
            <div class="left">
              <img src="@/assets/images/start/thought4.png" class="thought4" />
              <img src="@/assets/images/start/thought3.png" class="thought3" />
            </div>
            <div class="right">
              <img src="@/assets/images/start/thought1.png" class="thought1" />
              <img src="@/assets/images/start/thought2.png" class="thought2" />
            </div>
          </div>
          <div class="text-container">
            <div class="text-container-inner">
              <LikaRatt class="lika-ratt" />
              <!--  <img
                src="@/assets/images/start/lika_ratt.png"
                class="sexkampen"
              /> -->
              <div class="text-box">
                {{ $store.getters['getSetting']('site.description') }}
              </div>
            </div>
          </div>
        </div>
        <AboutSrhrButton />
        <div class="label">Hur vill du quizza?</div>
        <router-link :to="{ name: 'AvatarBuilder' }" class="single-player">
          <RoundedButton>Mot dig själv</RoundedButton>
        </router-link>
        <router-link :to="{ name: 'PartyJoin' }" class="party-player">
          <RoundedButton>Jag har en kod</RoundedButton>
        </router-link>
      </div>
    </div>
    <div class="footer-container">
      <div class="logo">
        <img src="@/assets/images/start/logo.png" />
      </div>
      <div class="party-create">
        <router-link :to="{ name: 'ChooseCategories' }">
          Jag vill leda ett quiz
          <ArrowRight
            class="arrow-right"
            viewBox="0 0 25 27"
            width="14"
            height="14"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRight from '@/assets/images/arrow-right-grey.svg';
import LikaRatt from '@/assets/images/start/lika_ratt.svg';
import RoundedButton from '@/components/RoundedButton';
import AboutSrhrButton from '@/components/AboutSrhrButton';

export default {
  components: {
    ArrowRight,
    LikaRatt,
    RoundedButton,
    AboutSrhrButton,
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-flow: column nowrap;
}
.top {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.text-container-inner {
  position: relative;
  margin-top: 20px;

  font-size: 15/375 * 100 + vw;

  border-radius: 30px;
  /*   border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1); */
  border: 5px solid rgba(0, 0, 0, 0.1);
}
.sexkampen {
  width: 100%;
  height: auto;
  position: absolute;
  top: -30/14 * 1em;
  left: 0;
}

.lika-ratt {
  width: 80%;
  height: auto;
  position: absolute;
  top: -30/14 * 1em;
  left: 0;
  right: 0;
  margin: auto;
}
.text-box {
  border-radius: 25px;
  background-color: #fef7f1;
  padding: 45/14 * 1em 10/14 * 1em 30/14 * 1em;
  font-weight: 700;
  font-style: italic;

  line-height: 150%;
  /* or 22px */

  text-align: center;
}
.thoughts-container {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-size: 1vw;

  .right {
    margin-left: auto;
  }

  .thought3 {
    margin-left: -17%;
    width: 66/375 * 100 + em;
  }
  .thought2 {
    margin-left: -17%;
    width: 70/375 * 100 + em;
  }
  .thought1 {
    position: relative;
    z-index: 10;
    width: 83/375 * 100 + em;
  }
  .thought4 {
    width: 72/375 * 100 + em;
  }
}

.label {
  color: #fff;

  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.footer-container {
  padding: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.logo {
  margin-top: auto;
  flex: 0 0 136px;
  max-height: 46px;
  img {
    width: 136px;
  }
}
.party-create {
  display: block;

  a {
    color: #fff;
    font-weight: 700;
    font-style: italic;
    text-decoration: none;
  }
  .arrow-right {
    vertical-align: middle;
  }
}

@media (min-width: 750px) {
  .footer-container {
    justify-content: center;
  }
  .party-create {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .thoughts-container {
    font-size: 7.5px;
  }
  .text-container-inner {
    margin-top: 50px;
    font-size: 30px;
  }
}
</style>
