<template>
  <div class="multi-choice-button" :class="classes" @click="onClick">
    <slot />
    <InfoButton
      v-if="correct && hasFacts"
      class="info-button"
      @click="onInfoClick"
    />
  </div>
</template>

<script>
import InfoButton from '@/components/InfoButton';

export default {
  components: {
    InfoButton,
  },
  props: {
    correct: { type: Boolean, default: () => false },
    hasFacts: { type: Boolean, default: () => false },
    index: { type: Number, default: () => -1 },
    locked: { type: Boolean, default: () => false },
    selected: { type: Boolean, default: () => false },
    validate: { type: Boolean, default: () => false },
    wrong: { type: Boolean, default: () => false },
  },
  computed: {
    classes() {
      let c = [];
      if (this.selected) {
        c.push('selected');
      }
      if (this.wrong) {
        c.push('wrong');
      }
      if (this.correct) {
        c.push('correct');
      }
      if (this.validate) {
        c.push('validate');
      }
      if (this.locked) {
        c.push('locked');
      }
      return c;
    },
  },
  methods: {
    onClick() {
      if (!this.locked) {
        this.$emit('click', this.index);
      }
    },
    onInfoClick() {
      this.$emit('info');
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-choice-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  min-height: 54px;
  margin: 14px 24px;
  padding: 4px 16px;

  font-family: 'HelveticaNeue', sans-serif;
  font-size: 14px;
  line-height: 110%;
  font-weight: 700;
  text-align: center;
  color: #303030;

  cursor: pointer;
  user-select: none;

  border: 3px solid rgba(0, 94, 184, 0.2);
  border-radius: 30px;

  -webkit-tap-highlight-color: transparent;
  transition: color 0.2s, background 0.2s, opacity 0.2s linear 0.5s,
    border-color 0.2s;

  &:active {
    background-color: $tap-highlight-color;
  }
  &.validate {
    &:not(.correct) {
      opacity: 0.2;
    }
  }

  &.selected {
    color: #fff;
    background: #303030;
  }
  &.correct {
    color: #fff;
    border-color: #2ca386;
    background: #2ca386;
    &:not(.selected) {
      transition-delay: 0.5s;
    }
  }
  &.wrong {
    color: #fff;
    border-color: #aa112c;
    background: #aa112c;
  }
  &.locked {
    cursor: not-allowed;
  }
}

.info-button {
  position: absolute;
  top: -30px;
  right: 15px;
}
</style>
