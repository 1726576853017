<template>
  <div class="content">
    <div class="desktop-view">
      <transition name="fade" mode="out-in">
        <div v-if="!showResult" key="question">
          <DesktopLogo class="desktop-logo" />
          <Card class="card" :minheight="430">
            <RoundedLabel class="question-counter" small noborder>
              {{ questionCounter }}
            </RoundedLabel>
            <RoundedLabel class="party-code" small noborder>
              kod: {{ partyCode }}
            </RoundedLabel>
            <Question
              v-for="(question, index) in quiz.questions"
              v-show="index === current_question_index"
              :key="question.id"
              :question="question"
              :validate="current_question_index === showResult"
              class="question"
            />
            <RoundedLabel>Svarat: {{ receivedAnswers }}</RoundedLabel>
          </Card>
          <RoundedButton @click="showQuestionResult">Visa svaren</RoundedButton>
        </div>
        <div v-else key="result">
          <DesktopLogo class="desktop-logo" />
          <Card class="card">
            <RoundedLabel class="question-counter" small noborder>
              {{ questionCounter }}
            </RoundedLabel>
            <RoundedLabel class="party-code" small noborder>
              kod: {{ partyCode }}
            </RoundedLabel>
            <Heading mb30 desktop class="question-title">
              {{ current_question_indexTitle }}
            </Heading>
            <div class="correct-answer-container">
              <Heading desktop>
                Rätt svar:
                <span
                  v-if="questionType != 'slider'"
                  class="correct-answer-marker"
                  :style="{ background: correctAnswerColor }"
                ></span>
                <span class="correct-answer">{{ correctAnswer }}</span>
                <InfoButton class="facts-toggle" @click="toggleModal" />
              </Heading>
            </div>

            <component
              :is="resultComponent"
              :data="questionData"
              :labels="questionLabels"
              :correct="correctAnswer"
            />

            <RoundedLabel class="received-answers-counter">
              Svarat: {{ receivedAnswers }}
            </RoundedLabel>
          </Card>

          <RoundedButton v-if="!lastQuestion" @click="nextQuestion">
            Nästa fråga
          </RoundedButton>
          <RoundedButton v-if="lastQuestion" @click="finishQuiz">
            Till summering
          </RoundedButton>
          <transition name="fade">
            <Modal
              v-if="showInfoModal"
              heading="Fakta"
              :html-content="questionFacts"
              @close="toggleModal"
            />
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DesktopLogo from '@/components/DesktopLogo';
import Accordion from '@/components/Accordion';
import Question from '@/components/AdminQuestion';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Heading from '@/components/Heading';
import MultiChoiceResult from '@/components/MultiChoiceResult';
import BooleanResult from '@/components/BooleanResult';
import InfoButton from '@/components/InfoButton';
import SliderResult from '@/components/SliderResult';
import RoundedButton from '@/components/RoundedButton';
import RoundedLabel from '@/components/RoundedLabel';
import UPDATE_PARTY from '@/graphql/UpdateParty.gql';
import PARTY_BY_CODE from '@/graphql/PartyByCode.gql';
import UPDATE_PARTICIPANT_STATE from '@/graphql/UpdateParticipantState.gql';

import echoInstance from '@/websocket';

export default {
  apollo: {
    quiz: {
      query: PARTY_BY_CODE,
      variables() {
        return {
          code: sessionStorage.getItem('party_admin_code'),
        };
      },
      update(data) {
        return data.partyByCode.quiz;
      },
      result(data) {
        //console.log(data.data);
        this.participants = data.data.partyByCode.participants;
        this.prepareResultDiagram();
        this.setQuestionType();
      },
    },
  },
  components: {
    Accordion,
    Card,
    Modal,
    Heading,
    InfoButton,
    multichoiceResult: MultiChoiceResult,
    sliderResult: SliderResult,
    booleanResult: BooleanResult,
    RoundedButton,
    RoundedLabel,
    Question,

    DesktopLogo,
  },
  data() {
    return {
      echoInstance: null,
      participants: [],
      current_question_index: -1,
      current_question_indexTitle: '',
      lastQuestion: false,
      partyCode: false,
      questionType: false,
      quiz: false,
      questionData: [],
      questionLabels: [],
      showResult: false,
      showInfoModal: false,
    };
  },
  computed: {
    receivedAnswers() {
      //const partcipantCount = this.participants.length;
      let answerCount = 0;

      this.participants.forEach((participant) => {
        //console.log('parti', participant);
        if (participant.answers) {
          participant.answers.forEach((answer) => {
            if (
              answer.question.id ===
              this.quiz.questions[this.current_question_index].id
            ) {
              answerCount += 1;
            }
          });
        }
      });
      return answerCount;
      //return answerCount + '/' + partcipantCount;
    },
    resultComponent() {
      return this.questionType + 'Result';
    },
    questionHasFacts() {
      if (this.quiz) {
        return this.quiz.questions[this.current_question_index].facts;
      } else {
        return false;
      }
    },
    questionCounter() {
      if (this.quiz) {
        return (
          this.current_question_index + 1 + '/' + this.quiz.questions.length
        );
      } else {
        return '--';
      }
    },
    questionFacts() {
      if (this.quiz) {
        return this.quiz.questions[this.current_question_index].facts;
      } else {
        return '';
      }
    },
    correctAnswer() {
      let correctAnswer;
      let questionTypeComponent =
        this.quiz.questions[this.current_question_index][this.questionType];
      const correct = questionTypeComponent.correct;
      switch (this.questionType) {
        case 'boolean':
          if (correct) {
            correctAnswer = questionTypeComponent.trueTitle;
          } else {
            correctAnswer = questionTypeComponent.falseTitle;
          }
          break;
        case 'multichoice':
          correctAnswer = questionTypeComponent['alternative' + correct];
          break;
        case 'slider':
          correctAnswer = correct;
          break;
      }
      return correctAnswer;
    },
    correctAnswerColor() {
      const colors = [
        '#F9B000',
        '#93328e',
        '#005eb8',
        '#e50076',
        '#003c68',
        '#008264',
      ];
      switch (this.questionType) {
        case 'boolean':
          return colors[
            this.quiz.questions[this.current_question_index].boolean.correct
          ];
        case 'multichoice':
          return colors[
            this.quiz.questions[this.current_question_index].multichoice
              .correct - 1
          ];
      }
      return '#000';
    },
  },
  watch: {
    current_question_index(val) {
      // Spara index utifall att admin tappar kontakten eller nåt
      sessionStorage.setItem('party_admin_cqi', val);

      this.prepareResultDiagram();
      this.setQuestionType();
    },
    participants() {},
  },
  created() {
    this.partyCode = sessionStorage.getItem('party_admin_code');
    const storedQuestionIndex = sessionStorage.getItem('party_admin_cqi');
    if (storedQuestionIndex) {
      this.current_question_index = parseInt(storedQuestionIndex, 10);
      this.prepareResultDiagram();
      this.setQuestionType();
    } else {
      this.current_question_index = 0;
      this.$firestore.partyRef.set(
        { current_question_index: 0, status: 1 },
        { merge: true }
      );

      sessionStorage.setItem('party_admin_cqi', '0');
    }

    this.echoInstance = echoInstance
      .join(`likaratt.${this.partyCode}`)
      .joining((user) => {
        //console.log('joining', user);
        this.setParticipantStatus(user.id, 'online');
      })
      .leaving((user) => {
        //console.log('leaving', user);
        this.setParticipantStatus(user.id, 'offline');
      })
      .listen('PartyStatusUpdated', (e) => {
        //console.log('PartyStatusUpdated', e);
        this.participants = e.party.participants;
      })
      .listen('AnswerCreated', (e) => {
        //console.log('AnswerCreated', e);
        this.participants = e.party.participants;
      });
  },

  unmounted() {
    this.echoInstance = null;
  },
  methods: {
    toggleModal() {
      //console.log('toggleModal');
      this.showInfoModal = !this.showInfoModal;
    },
    finishQuiz() {
      //Sätt status på quz i firestore till ended = 2;
      this.updateParty(
        {
          code: this.partyCode,
          status: 2,
        },
        () => {
          this.$router.push({ name: 'PartyAdminEnd' });
        }
      );
    },
    nextQuestion() {
      this.current_question_index += 1;
      if (this.current_question_index === this.quiz.questions.length - 1) {
        this.lastQuestion = true;
      }
      this.showResult = false;

      this.updateParty({
        code: this.partyCode,
        currentQuestion: this.current_question_index,
      });
    },
    prepareResultDiagram() {
      // Förbered resultat diagrammet för frågan
      if (!this.quiz || this.current_question_index == -1) {
        return;
      }

      const question = this.quiz.questions[this.current_question_index];
      let cats = [];
      this.questionData = [];
      if (question.multichoice) {
        for (let i = 1; i < 7; i += 1) {
          if (question.multichoice['alternative' + i]) {
            cats.push(question.multichoice['alternative' + i]);
            // eftersom svarsalternativen är baserade på ett i databsen
            this.questionData[i - 1] = 0;
          }
        }
      } else if (question.slider) {
        const range = question.slider.max - question.slider.min;
        for (let i = 0; i <= range; i += 1) {
          cats.push((i + question.slider.min).toString());
          this.questionData[i] = [];
        }
      } else if (question.boolean) {
        cats.push('Falskt');
        cats.push('Sant');
        this.questionData = [0, 0];
      }

      this.questionLabels = cats;
    },
    setQuestionType() {
      if (!this.quiz || this.current_question_index == -1) {
        return;
      }

      const question = this.quiz.questions[this.current_question_index];
      if (question.multichoice) {
        this.questionType = 'multichoice';
      } else if (question.slider) {
        this.questionType = 'slider';
      } else if (question.boolean) {
        this.questionType = 'boolean';
      }
    },
    showQuestionResult() {
      this.showResult = true;

      this.current_question_indexTitle =
        this.quiz.questions[this.current_question_index].question;

      this.participants.forEach((participant) => {
        participant.answers.forEach((answer) => {
          if (
            answer.question.id ===
            this.quiz.questions[this.current_question_index].id
          ) {
            // eftersom svaren för multichoice är baserade på ett
            // måste vi minska svarsindexet med och plussa på
            if (this.questionType === 'multichoice') {
              this.questionData[answer.value - 1] += 1;
            } else if (this.questionType === 'slider') {
              const answerIndex =
                answer.value -
                this.quiz.questions[this.current_question_index].slider.min;
              this.questionData[answerIndex].push(participant.avatar);
            } else {
              this.questionData[answer.value] += 1;
            }
          }
        });
      });

      // Se till att alla deltagare får signal att visa resultatet.
      this.updateParty({
        code: this.partyCode,
        showResult: this.current_question_index,
      });
    },

    updateParty(data, callback) {
      this.$apollo
        .mutate({
          mutation: UPDATE_PARTY,
          variables: data,
        })
        .then((response) => {
          if (callback) {
            callback(response);
          }
        });
    },

    setParticipantStatus(participantId, status) {
      this.$apollo.mutate({
        mutation: UPDATE_PARTICIPANT_STATE,
        variables: {
          id: parseInt(participantId, 10),
          state: status,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  z-index: 1;
  margin-bottom: 15/14 * 1rem;
}
.desktop-logo {
  z-index: 2;
}
.question {
  margin-top: 60/14 * 1rem;
  margin-bottom: 40/14 * 1rem;
}
.multi-choice-button {
  max-width: 440px;
  margin: auto;
}
.question-counter {
  position: absolute;
  top: 27px;
  left: 20px;
}
.party-code {
  position: absolute;
  top: 27px;
  right: 20px;
}
.question-title {
  max-width: 720px;
  margin: 40px auto 20px auto;
}
.correct-answer-container {
  margin-bottom: 15px;
}

.correct-answer-marker {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  height: 30px;
  width: 30px;
  vertical-align: text-top;
}

.correct-answer {
  vertical-align: middle;
  font-weight: 400;
}
.facts {
  text-align: left;
  padding: 0 21px;
}
.facts-toggle {
  position: relative;
  top: -6px;
  margin-left: 16px;

  font-size: 1rem;
  display: inline-block;
  line-height: 1;
}
</style>
