<template>
  <div class="button-wrapper">
    <div class="small-button" :class="{ disabled: disabled }" @click="onClick">
      <span><slot></slot></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {},
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: inline-block;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  max-width: 385px;

  border: 4px solid rgba(0, 94, 184, 0.3);
  border-radius: 45px;
}
.small-button {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 26/14 * 1rem;
  min-width: 150px;

  margin: auto;
  padding: 5/14 * 1rem 15/14 * 1rem;
  border-radius: 41px;

  background: #005eb8;

  font-family: 'HelveticaNeue', 'Open sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #ffffff;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.1s;

  cursor: pointer;

  span {
    position: relative;
    top: 1px;
  }

  &:active {
  }

  &.disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3);
    background-color: #e7e7e7;
  }
}
</style>
