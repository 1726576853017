<template>
  <div class="slider">
    <StickySlider
      v-model="value"
      :disabled="validate || locked"
      :min="slider.min"
      :max="slider.max"
      :validate="validate"
      :admin="admin"
      :correct-value="slider.correct"
      class="sticky-slider answer-slider"
      @input="onChange"
    >
      <InfoButton v-if="hasFacts" class="info-button" @click="onInfoClick" />
    </StickySlider>

    <div class="description" :class="{ nodescription: nodescription }">
      Drag i reglaget för att justera
    </div>
  </div>
</template>

<script>
import InfoButton from '@/components/InfoButton';
import StickySlider from './StickySlider';

export default {
  components: {
    InfoButton,
    StickySlider,
  },
  props: {
    hasFacts: { type: Boolean, default: () => false },
    locked: { type: Boolean, default: () => false },
    slider: { type: Object, default: () => {} },
    validate: { type: Boolean, default: () => false },
    admin: { type: Boolean, default: () => false },
    nodescription: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      value: this.slider.min,
      interacted: false,
      correctValue: 0,
    };
  },
  computed: {
    validatedClasses() {
      let c = [];

      if (this.validate) {
        c.push('validate');
      }
      if (this.interacted) {
        c.push('interacted');
      }

      if (this.value === this.slider.correct) {
        c.push('correct');
      } else {
        c.push('wrong');
      }
      return c;
    },
  },
  watch: {
    validate(newValidateValue) {
      if (newValidateValue === true) {
        const questionScore = this.value === this.slider.correct ? 1 : 0;
        this.$emit('score-changed', questionScore);
      }
    },
    slider(newValue) {
      this.correctValue = newValue.correct;
    },
  },
  methods: {
    onChange() {
      if (!this.interacted) {
        this.interacted = true;
      }
      this.$emit('change', this.value);
    },
    onInfoClick() {
      this.$emit('info');
    },
  },
};
</script>

<style lang="scss">
.slider {
  position: relative;

  .description {
    margin-top: 40px;
    font-weight: 300;
    font-size: 14px;
    line-height: 110%;
    text-align: center;

    color: #303030;
    &.nodescription {
      display: none;
    }
  }

  .info-button {
    position: absolute;
    top: -38px;
    left: 20px;
    z-index: 1;
  }
}
</style>
