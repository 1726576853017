<template>
  <div
    class="sticky-slider"
    :class="{
      disabled,
      wrong: validate && localValue != correctValue,
      correct: validate && localValue == correctValue,
      interacted,
    }"
  >
    <div ref="sliderContainer" class="slider-container" @mousemove="moveSlide">
      <div class="track" />
      <div
        ref="handle"
        class="handle"
        @mousedown="startSlide"
        @touchstart="startSlide"
        @touchmove="moveSlide"
      >
        <div class="handle-inner">
          <span v-if="!admin">{{ localValue }}</span>
        </div>
      </div>
      <transition name="fade">
        <div v-show="validate" ref="correctAnswer" class="correct-answer">
          <span>
            {{ correctValue }}
            <slot></slot>
          </span>
        </div>
      </transition>
    </div>
    <div v-if="admin" class="scale">
      <span>{{ min }}</span>
      <span>{{ max }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    correctValue: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      inSlideMode: false,
      sliderPos: 0,
      handleWidth: 50,
      localValue: this.min,
      interacted: false,
    };
  },
  watch: {
    validate(newValidateValue) {
      if (newValidateValue === true) {
        if (this.correctValue) {
          this.setCorrectAnswer(this.correctValue);
        }
      }
    },
  },

  mounted() {
    if (this.correctValue) {
      this.setCorrectAnswer(this.correctValue);
    }
  },

  methods: {
    startSlide(evt) {
      if (this.disabled || this.validate) {
        return;
      }
      evt.preventDefault();

      this.interacted = true;
      this.inSlideMode = true;
      this.sliderPos = this.getXpos(evt);
      this.$refs.handle.style.transform =
        'translateX(' + this.sliderPos + 'px)';

      window.addEventListener('touchend', this.endSlide);
      window.addEventListener('mouseup', this.endSlide);
    },
    endSlide(evt) {
      evt.preventDefault();
      window.removeEventListener('touchend', this.endSlide);
      window.removeEventListener('mouseup', this.endSlide);
      this.inSlideMode = false;

      if (evt instanceof MouseEvent) {
        this.sliderPos = this.getXpos(evt);
      }
      this.emitValue(this.sliderPos);
    },
    moveSlide(evt) {
      evt.preventDefault();
      if (!this.inSlideMode) {
        return;
      }
      this.sliderPos = this.getXpos(evt);
      this.$refs.handle.style.transform =
        'translateX(' + this.sliderPos + 'px)';
      this.emitValue(this.sliderPos);
    },
    emitValue(xPos) {
      const value = this.calculateValue(xPos);
      this.$emit('input', value);
    },
    calculateValue(xPos) {
      let pagePos = this.$refs.sliderContainer.getBoundingClientRect();
      let totalWidth = pagePos.width - 50;

      let value = (xPos / totalWidth) * (this.max - this.min) + this.min;
      value = Math.round(value);
      this.localValue = value;
      return value;
    },
    getXpos(evt) {
      let xPos = 0;
      let pagePos = this.$refs.sliderContainer.getBoundingClientRect();

      if (evt instanceof MouseEvent) {
        xPos += evt.clientX;
      } else if (evt instanceof TouchEvent) {
        xPos += evt.touches[0].clientX;
      }

      const returnValue = Math.max(
        0,
        Math.min(pagePos.width - 50, xPos - pagePos.x - 25)
      );

      //console.log('xPos', returnValue);

      return returnValue;
    },

    setCorrectAnswer(value) {
      const rect = this.$refs.sliderContainer.getBoundingClientRect();
      const totalWidth = rect.width - 50;

      const sliderPos = parseInt(
        ((value - this.min) / (this.max - this.min)) * totalWidth,
        10
      );

      this.sliderPos = sliderPos;
      this.$refs.correctAnswer.style.transform =
        'translateX(' + this.sliderPos + 'px)';
    },
  },
};
</script>

<style lang="scss" scoped>
$sliderWidth: 50px;

.sticky-slider {
  width: 100%;
  position: relative;

  &.disabled {
    cursor: not-allowed;
  }
  &:not(.disabled) {
    .handle {
      cursor: pointer;
    }
  }

  &.interacted {
    .slider-container .handle .handle-inner {
      background: #003c68;
      color: #ffffff;
    }
  }

  &.correct {
    .slider-container .handle .handle-inner {
      color: #fff;
      border: 1px solid #2ca386;
      background: #2ca386;
      opacity: 0;
    }
  }
  &.wrong {
    .slider-container .handle .handle-inner {
      opacity: 1;
      color: #fff;
      border: 0;
      border: 1px solid #efccd5;
      background: #efccd5;
    }
  }

  .slider-container {
    width: 100%;
    height: 100px;
    padding: 0 25px;

    .track {
      position: relative;
      top: 50px;
      height: 4px;
      border-radius: 2px;
      width: 100%;
      background-color: rgba(0, 94, 184, 0.3);
    }
    .handle {
      height: $sliderWidth;
      width: $sliderWidth;
      position: relative;
      left: -25px;
      top: 24px;

      .handle-inner {
        height: $sliderWidth;
        width: $sliderWidth;
        border-radius: 50%;
        display: block;
        border: 0;
        font-family: 'HelveticaNeue', sans-serif;
        font-size: 14px;
        line-height: 110%;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;

        background: #ffffff;
        border: 1px solid #003c68;
        box-sizing: border-box;
        transition: color 0.2s, background 0.2s, border 0.2s,
          opacity 0.2s linear 0.5s;
      }
    }

    .correct-answer {
      position: absolute;
      top: 28px;
      left: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(44, 163, 134, 1);
      color: #fff;
      font-family: 'HelveticaNeue', sans-serif;
      font-size: 14px;
      line-height: 110%;
      font-weight: 700;
      z-index: 1;
    }
  }

  .scale {
    position: relative;
    top: -20px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    font-weight: 600;
  }
}
</style>
