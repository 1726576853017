<template>
  <div class="content v-center">
    <Card><Heading>Snyggt jobbat!</Heading></Card>
    <RoundedButton class="btn" @click="onShareClick">
      Dela Likarättskampen
    </RoundedButton>
    <router-link :to="{ name: 'Start' }">
      <RoundedButton class="btn">Tillbaka till startsidan</RoundedButton>
    </router-link>
  </div>
</template>

<script>
import Card from '@/components/Card';
import RoundedButton from '@/components/RoundedButton';
import Heading from '@/components/Heading';
import Utils from '@/utils/Utils';
export default {
  components: {
    Card,
    Heading,
    RoundedButton,
  },
  methods: {
    onShareClick() {
      if (navigator.share) {
        const shareText =
          this.$store.getters['getSetting']('site.title') +
          ' - ' +
          this.$store.getters['getSetting']('site.description');
        navigator.share({
          text: shareText,
          //text: 'Sexkampen - Ett kunskapsspel om sex, reproduktion, rättigheter, dig själv, dina medmänniskor och andra livsviktiga grejer!',
          url: process.env.VUE_APP_SHARE_URL,
        });
      } else {
        Utils.copyToClipboard(process.env.VUE_APP_SHARE_URL);
        alert('Länken är kopierad till urklipp');
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
