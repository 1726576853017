<template>
  <div class="modal-wrapper" @click.self="close">
    <div class="modal-inner">
      <div class="close" @click="close">
        <Close />
      </div>
      <Card class="card">
        <Heading v-if="heading" class="heading">{{ heading }}</Heading>
        <div class="html-content">
          <div v-html="htmlContent" />
          <slot></slot>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import Close from '@/assets/images/close.svg';
import Heading from '@/components/Heading';
export default {
  components: {
    Card,
    Close,
    Heading,
  },
  props: {
    htmlContent: {
      type: String,
      default: () => '',
    },
    heading: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0 $padding-h;

  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal-inner {
  max-height: 100%;
  width: 100%;
  max-width: $view-max-width;
  padding-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  > * {
    flex: 0 0 auto;
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
}
.card {
  margin: 0;
}
p {
  margin: 4em 0;
}
</style>

<style lang="scss">
.modal-wrapper {
  .html-content {
    text-align: left;
    padding: 0 15px;
    p {
      margin: 1em 0;
      line-height: 160%;
    }
    iframe,
    img {
      max-width: 100%;
    }
    a {
      color: $purple;
    }
  }

  @media (min-width: 750px) {
    .modal-inner {
      max-width: 1150px;
    }
    .heading {
      font-size: 40px;
    }
    .html-content {
      font-size: 22px;
    }
  }
}
</style>
