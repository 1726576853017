<template>
  <div class="boolean-result">
    <apexchart
      width="50%"
      type="donut"
      :options="options"
      :series="series"
      class="chart"
    ></apexchart>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
export default {
  components: {
    apexchart,
  },
  props: {
    data: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
  },
  data() {
    return {
      options: {
        labels: this.labels,
        colors: ['#F9B000', '#93328e'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'bottom',
          fontFamily: 'Open sans',
          fontSize: 20,
          fontWeight: 600,
          horizontalAlign: 'center',
          itemMargin: {
            horizontal: 6,
            vertical: 6,
          },
          markers: {
            width: 16,
            height: 16,
            radius: 0,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      series: this.data,
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.chart {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.boolean-result {
  margin-bottom: 16px;
}
</style>
