import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import Vuex from 'vuex';

import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import Schema from '@/graphql/schema.gql';
import { resolvers } from '@/graphql/resolvers.js';
//import VueFirestore from 'vue-firestore';
import store from './store';

import Default from './layouts/Default';
import Desktop from './layouts/Desktop';

// eslint-disable-next-line
import normalize from './scss/normalize.scss';
// eslint-disable-next-line
import styles from './scss/app.scss';

import echoInstance from './websocket';

const cache = new InMemoryCache();
let linkUrl = process.env.VUE_APP_API_URL;

const apolloClient = new ApolloClient({
  link: createHttpLink({ uri: linkUrl }),
  cache,
  typeDefs: Schema,
  resolvers: resolvers,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

apolloClient
  .query({
    query: require('@/graphql/Settings.gql'),
  })
  .then(({ data }) => {
    store.commit('setSettings', data.settings);
    store.commit('settingsLoaded', true);
  });

cache.writeData({
  data: {
    session: {
      __typename: 'Session',
      id: 1,
      mode: 'SINGLE',
      healthcare: null,
      party: {
        id: 1,
        code: false,
        __typename: 'LocalParty',
      },
    },
  },
});

Vue.use(VueApollo);
//Vue.use(VueFirestore, { key: 'id' });
Vue.use(echoInstance, { key: 'id' });
Vue.use(Vuex);

Vue.component('DefaultLayout', Default);
Vue.component('DesktopLayout', Desktop);

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
