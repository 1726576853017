<template>
  <div class="outer-wrapper">
    <div id="app" ref="wrapper" class="wrapper">
      <transition name="slide">
        <slot></slot>
      </transition>
      <transition name="fade">
        <div v-show="showSrhr" class="SRHR">
          <SmallButton @click="toggleSrhrModal">Vad är SRHR?</SmallButton>
        </div>
      </transition>
      <transition name="fade">
        <Modal v-if="aboutSrhrModalVisible" @close="toggleSrhrModal">
          <h1>{{ $store.getters['getSetting']('about.title') }}</h1>
          <div v-html="$store.getters['getSetting']('about.read_about')" />
        </Modal>
      </transition>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import SmallButton from '@/components/SmallButton';
import { mapState } from 'vuex';

export default {
  name: 'Sexkampen',
  components: { Modal, SmallButton },

  computed: {
    ...mapState(['aboutSrhrModalVisible']),
    showSrhr() {
      return this.$route.meta.showAboutSrhr;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.$refs.wrapper.style.height = window.innerHeight + 'px';
    },
    toggleSrhrModal() {
      if (this.aboutSrhrModalVisible) {
        this.$store.dispatch('hideAboutSrhr');
      } else {
        this.$store.dispatch('showAboutSrhr');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: $view-max-width;
  height: 100vh;
  margin: 0 auto;
  overflow: visible;
  > * {
    flex: 0 0 auto;
  }

  .SRHR {
    position: fixed;
    top: auto;
    padding: 10px;
    bottom: 0px;
    left: 0;
    right: 0;
  }
}
</style>
