<template>
  <div class="desktop-logo">
    <div class="lika-ratt-wrapper">
      <LikaRatt class="lika-ratt" />
    </div>
  </div>
</template>

<script>
import LikaRatt from '@/assets/images/start/lika_ratt.svg';
export default {
  components: {
    LikaRatt,
  },
};
</script>

<style lang="scss" scoped>
.desktop-logo {
  position: relative;
  margin-bottom: -60px;
}
.lika-ratt {
  width: 100%;
  max-width: 333px;
  height: auto;
  left: 0;
}
</style>
